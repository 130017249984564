import { SqmFace } from "./roiResult";

export type FaceInfos = {
  embellishmentUv2d3dEffect: number;
  digitalHotFoil2d3dEffect: number;
  digitalTrueHotFoil2d3dEffect?: number;
  antifoil2d3dEffect?: number;
  opv2d3d: number;
  opv: boolean;
  optimizedFoilUsagePerSheet: OptimizedFoilUsagePerSheet;
};

export type OptimizedFoilUsagePerSheet = {
  maximizedFoilWidthUsageRollWidth: number;
  maximizedFoilLengthUsagePerSheet: number;
  FoilMasterPerRollUpDown: number;
  TotalFoilLengthUsagePerSheet: number;
};

export type FoilInformation = {
  foilMasterRollWidth: number;
  foilRollLength: number;
  foilMasterRollCost: number;
};

export type AlphaJetGlobalPrintingCosts = {
  costPerInkLiter: number;
  costPerUvVarnishLiter: number;
  costPerAntifoilLiter: number;
  costPerLiterB1: number;
};

export type RoiParams = {
  numberOfSheets: number;
  paperCostForJob: number;
  primerCost: number;
  isInternalEcoConso: boolean;
  //doubleSided: boolean;
  paperFormat: { length: number; width: number };
  imageFormat: { length: number; width: number };
  alphaJetGlobalPrintingCosts: AlphaJetGlobalPrintingCosts;
  front: FaceInfos;
  back: FaceInfos;
  foilInformation: FoilInformation;
  isSqm: boolean;
  sqmPrice: number;
  foilRollSize: number;
  sqmFront: SqmFace[] | undefined;
  sqmBack: SqmFace[] | undefined;
};

export const StaticValueOfParameters: RoiParams = {
  numberOfSheets: 1,
  primerCost: 6.5,
  paperCostForJob: 0.17,
  isInternalEcoConso: true,
  alphaJetGlobalPrintingCosts: {
    costPerInkLiter: 96,
    costPerUvVarnishLiter: 73,
    costPerAntifoilLiter: 90,
    costPerLiterB1: 0.00617,
  },
  foilInformation: {
    foilMasterRollWidth: 640,
    foilRollLength: 400,
    foilMasterRollCost: 110,
  },
  isSqm: true,
  sqmPrice: 0.45,
  foilRollSize: 320,
  front: {
    embellishmentUv2d3dEffect: 7,
    antifoil2d3dEffect: 7,
    digitalHotFoil2d3dEffect: 7,
    digitalTrueHotFoil2d3dEffect: 7,
    opv2d3d: 3,
    opv: false,
    optimizedFoilUsagePerSheet: {
      maximizedFoilWidthUsageRollWidth: 700,
      maximizedFoilLengthUsagePerSheet: 1000,
      TotalFoilLengthUsagePerSheet: 1050.8,
      FoilMasterPerRollUpDown: 50.8,
    },
  },
  back: {
    embellishmentUv2d3dEffect: 7,
    digitalHotFoil2d3dEffect: 7,
    digitalTrueHotFoil2d3dEffect: 7,
    opv2d3d: 3,
    opv: false,
    optimizedFoilUsagePerSheet: {
      maximizedFoilWidthUsageRollWidth: 700,
      maximizedFoilLengthUsagePerSheet: 1000,
      TotalFoilLengthUsagePerSheet: 1050.8,
      FoilMasterPerRollUpDown: 50.8,
    },
  },
  paperFormat: {
    length: 1020,
    width: 720,
  },
  imageFormat: {
    length: 1020,
    width: 720,
  },
  sqmBack: undefined,
  sqmFront: undefined,
};
