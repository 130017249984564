import { useUser } from "@mgi-labs/mgi-id";
import { useEffect, useState } from "react";
import { useCalculatorList } from "../hooks/useCalculator";
import CalculatorTable from "./calculatorTable";
import { useParams } from "react-router-dom";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

function CalculatorListFetch({
  setMachineType,
}: {
  setMachineType: React.Dispatch<MachineModelType>;
}) {
  const [filterCustom, setFilterCustom] = useState<{
    search?: string;
  }>({});

  const user = useUser();

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  useEffect(() => {
    machineType && setMachineType(machineType);
  }, [machineType, setMachineType]);

  const { data: calculatorList, mutate } = useCalculatorList(
    machineType ?? "ALPHAJET"
  );

  useEffect(() => {
    mutate();
  }, [mutate, user]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  return calculatorList?.length !== undefined ? (
    <CalculatorTable
      events={calculatorList}
      filterCustom={filterCustom}
      setFilterCustom={setFilterCustom}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      mutate={mutate}
    />
  ) : null;
}

export default CalculatorListFetch;
