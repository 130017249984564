import React, { createContext, useContext } from "react";
import MachineBackend from "./machineBackend";

const BackendContext = createContext<MachineBackend>(null!);

export const MachineBackendProvider: React.FunctionComponent<{
  children: React.ReactNode;
  value: MachineBackend;
}> = ({ value, children }) => {
  return (
    <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
  );
};

export function useMachineBackend(): MachineBackend {
  return useContext(BackendContext);
}
