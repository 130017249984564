import { PdfPreflight } from "../../pdfPreflight.interface";

export const CALCULATOR_EQUIPMENTS_TYPES = ["ALPHAJET", "COMPACT"];

export const noPdfPreflightDefaultAlphajet: PdfPreflight = {
  name: "nopdf.pdf",
  step: 100,
  pdfPreflightResponse: [
    {
      page: 1,
      CMYKCoverage: {
        Cyan: 0,
        Magenta: 0,
        Yellow: 0,
        Black: 0,
      },
      otherColor: [
        {
          name: "foil",
          percent: 0,
        },
        {
          name: "varnish",
          percent: 0,
        },
        {
          name: "opv",
          percent: 0,
        },
      ],
      thumbnail: "",
      foils: [
        {
          name: "foil",
          surface: 734400,
          url: "",
          foils: [
            {
              cover: 734400,
              width: 1020,
              height: 720,
            },
          ],
        },
      ],
      PdfSize: {
        width: 1020,
        height: 720,
      },
    },
    {
      page: 2,
      CMYKCoverage: {
        Cyan: 0,
        Magenta: 0,
        Yellow: 0,
        Black: 0,
      },
      otherColor: [
        {
          name: "foil",
          percent: 0,
        },
        {
          name: "varnish",
          percent: 0,
        },
        {
          name: "opv",
          percent: 0,
        },
      ],
      thumbnail: "",
      foils: [
        {
          name: "foil",
          surface: 734400,
          url: "",
          foils: [
            {
              cover: 734400,
              width: 1020,
              height: 720,
            },
          ],
        },
      ],
      PdfSize: {
        width: 1020,
        height: 720,
      },
    },
  ],
};

export const noPdfPreflightDefaultCompact: PdfPreflight = {
  name: "nopdf.pdf",
  step: 100,
  pdfPreflightResponse: [
    {
      page: 1,
      CMYKCoverage: {
        Cyan: 0,
        Magenta: 0,
        Yellow: 0,
        Black: 0,
      },
      otherColor: [
        {
          name: "foil",
          percent: 0,
        },
        {
          name: "varnish",
          percent: 0,
        },
        {
          name: "opv",
          percent: 0,
        },
      ],
      thumbnail: "",
      foils: [
        {
          name: "foil",
          surface: 5000,
          url: "",
          foils: [
            {
              cover: 5000,
              width: 100,
              height: 50,
            },
          ],
        },
      ],
      PdfSize: {
        width: 100,
        height: 50,
      },
    },
    {
      page: 2,
      CMYKCoverage: {
        Cyan: 0,
        Magenta: 0,
        Yellow: 0,
        Black: 0,
      },
      otherColor: [
        {
          name: "foil",
          percent: 0,
        },
        {
          name: "varnish",
          percent: 0,
        },
        {
          name: "opv",
          percent: 0,
        },
      ],
      thumbnail: "",
      foils: [
        {
          name: "foil",
          surface: 5000,
          url: "",
          foils: [
            {
              cover: 5000,
              width: 100,
              height: 50,
            },
          ],
        },
      ],
      PdfSize: {
        width: 100,
        height: 50,
      },
    },
  ],
};
