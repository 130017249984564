export const ALL_APPS = [
  "approve",
  "roi",
  "dashboard",
  "jetforce",
  "tag-generator",
  "predict",
  "estimate",
  "appshop",
  "foilshop",
  "alphajet-viewer",
  "guidelines",
];

export function getAvailableApps(userApps: string[]): APPS_TYPE[] {
  return userApps.filter(
    (app) => ALL_APPS.includes(app) && !USER_APPS_EXTERNAL.includes(app)
  );
}
export function getUserApps(userApps: string[]): APPS_TYPE[] {
  return userApps.filter((app) => USER_APPS_EXTERNAL.includes(app));
}
export function getResourceApps(): APPS_TYPE[] {
  return ["guidelines" /*"foilshop", "appshop", "alphajet-viewer"*/];
}

export type APPS_TYPE = (typeof ALL_APPS)[number];

export const USER_APPS_EXTERNAL: APPS_TYPE[] = [
  "approve",
  "dashboard",
  "jetforce",
];

export interface AppsInfos {
  name: string;
  logo: string;
  color: string;
  link: string;
}

export {};
