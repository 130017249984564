import React from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { CalculatorConfig, mapConfigToCase } from "../calculatorTypes";

const SELECT_VALUES = [0, 3, 7, 14, 28, 35, 42, 56, 63, 77, 112];
const SELECT_VALUES_COMPACT = [7, 14, 21, 29, 36, 43, 51, 58, 73, 87, 102, 116];

const CompactSettings = ({
  doubleSided,
  hasAntifoil,
  hasFoil,
  antifoil,
  setAntifoil,
  foilA,
  setFoilA,
  foilB,
  setFoilB,
  config,
  trueFoil,
  setTrueFoil,
}: {
  doubleSided: boolean;
  hasAntifoil: boolean | undefined;
  hasFoil: boolean;
  antifoil: number;
  setAntifoil: React.Dispatch<number>;
  foilA: number;
  setFoilA: React.Dispatch<number>;
  foilB: number;
  setFoilB: React.Dispatch<number>;
  config: CalculatorConfig;
  trueFoil: number;
  setTrueFoil: React.Dispatch<number>;
}) => {
  const { t, i18n } = useTranslation();

  const configCase = mapConfigToCase(config);

  return (
    <div className={classNames(styles.onecontainer)}>
      <div className={classNames(styles.informations)}>
        <div className={classNames(styles.titlezone)}>
          <p className={classNames(styles.title)}>
            {t("varnish_thickness")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            {doubleSided && (
              <>
                <p>{t("front")}</p>
                <p>{t("back")}</p>
              </>
            )}
          </div>
        </div>

        {(configCase === "case1" ||
          configCase === "case2" ||
          configCase === "case3" ||
          configCase === "case4" ||
          configCase === "case5") && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t(
                configCase === "case3" || configCase === "case4"
                  ? "Clear Varnish (Foil)"
                  : "clearVarnish2d3d"
              )}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div
                className={classNames(
                  doubleSided ? styles.sideA : styles.sideB
                )}
              >
                <select
                  className={classNames(styles.select)}
                  name="foilA"
                  id="foilA"
                  defaultValue={foilA}
                  onChange={(e) => {
                    setFoilA(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES_COMPACT.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
              {doubleSided && (
                <div className={classNames(styles.sideB)}>
                  <select
                    className={classNames(styles.select)}
                    name="foilB"
                    id="foilB"
                    defaultValue={foilB}
                    onChange={(e) => {
                      setFoilB(parseFloat(e.target.value));
                    }}
                  >
                    {SELECT_VALUES.map((v) => (
                      <option value={v} key={v}>
                        {v} µm
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        )}

        {configCase === "case2" && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("Clear Varnish (Foil)")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div
                className={classNames(
                  doubleSided ? styles.sideA : styles.sideB
                )}
              >
                <select
                  className={classNames(styles.select)}
                  name="trueFoil"
                  id="trueFoil"
                  defaultValue={trueFoil}
                  onChange={(e) => {
                    setTrueFoil(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES_COMPACT.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {hasAntifoil && config.printer2 && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("antifoil2d3d")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div
                className={classNames(
                  doubleSided ? styles.sideA : styles.sideB
                )}
              >
                <select
                  className={classNames(styles.select)}
                  name="antifoil"
                  id="antifoil"
                  defaultValue={antifoil}
                  onChange={(e) => {
                    setAntifoil(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES_COMPACT.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CompactSettings;
