import React from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { CalculatorConfig, Devise } from "../calculatorTypes";

const CompactCosts = ({
  hasFoil,
  devise,
  setDevise,
  paperCost,
  setPaperCost,
  varnishCost,
  setVarnishCost,
  antifoilCost,
  setAntifoilCost,
  foilCost,
  setFoilCost,
  foilRollSize,
  setFoilRollSize,
  config,
}: {
  hasFoil: boolean;
  devise: Devise;
  setDevise: React.Dispatch<Devise>;
  paperCost: number;
  setPaperCost: React.Dispatch<number>;
  varnishCost: number;
  setVarnishCost: React.Dispatch<number>;
  antifoilCost: number;
  setAntifoilCost: React.Dispatch<number>;
  foilCost: number;
  setFoilCost: React.Dispatch<number>;
  foilRollSize: number;
  setFoilRollSize: React.Dispatch<number>;
  config: CalculatorConfig;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames(styles.onecontainer)}>
      <div className={classNames(styles.consommables)}>
        <div className={classNames(styles.titlezone)}>
          <p className={classNames(styles.title)}>
            {t("settings")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
        </div>
        <div className={classNames(styles.onecontent)}>
          <p>
            {t("devise")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <div className={classNames(styles.detailcontent)}>
                <label className={classNames(styles.select)} htmlFor="slct">
                  <select
                    id="slct"
                    onChange={(e) => setDevise(e.target.value as Devise)}
                    defaultValue={devise}
                  >
                    <option value="" disabled>
                      Select option
                    </option>
                    <option value="€">€</option>
                    <option value="$">$</option>
                    <option value="£">£</option>
                  </select>
                  <svg>
                    <use xlinkHref="#select-arrow-down"></use>
                  </svg>
                </label>

                <svg className={classNames(styles.sprites)}>
                  <symbol id="select-arrow-down" viewBox="0 0 10 6">
                    <polyline points="1 1 5 5 9 1"></polyline>
                  </symbol>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.onecontent)}>
          <p>
            {t("substratecost", {
              type: t("sqm"),
            })}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput)}
                type="text"
                name="paperC"
                id="paperC"
                defaultValue={paperCost}
                onChange={(e) => {
                  setPaperCost(parseFloat(e.target.value));
                }}
              />
              <span className={classNames(styles.unite)}>{devise}</span>
            </div>
          </div>
        </div>

        {(config.printer1 || config.ifoil) && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("clearVarnishCost")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div className={classNames(styles.sideA)}>
                <input
                  className={classNames(styles.paddinginput)}
                  type="text"
                  name="varnishC"
                  id="varnishC"
                  defaultValue={varnishCost}
                  onChange={(e) => {
                    setVarnishCost(parseFloat(e.target.value));
                  }}
                />
                <span className={classNames(styles.unite)}>{devise}</span>
              </div>
            </div>
          </div>
        )}
        {config.printer2 && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("antifoilcost")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div className={classNames(styles.sideA)}>
                <input
                  className={classNames(styles.paddinginput)}
                  type="text"
                  name="antifoil"
                  id="antifoil"
                  defaultValue={antifoilCost}
                  onChange={(e) => {
                    setAntifoilCost(parseFloat(e.target.value));
                  }}
                />
                <span className={classNames(styles.unite)}>{devise}</span>
              </div>
            </div>
          </div>
        )}
        {hasFoil && config.ifoil && (
          <>
            <div className={classNames(styles.onecontent)}>
              <p>
                {t("foilcost")}
                {i18n.language === "fr" ? " :" : ":"}
              </p>
              <div className={classNames(styles.sides)}>
                <div className={classNames(styles.sideA)}>
                  <input
                    className={classNames(styles.paddinginput)}
                    type="text"
                    name="foilC"
                    id="foilC"
                    defaultValue={foilCost}
                    onChange={(e) => {
                      setFoilCost(parseFloat(e.target.value));
                    }}
                  />
                  <span className={classNames(styles.unite)}>{devise}</span>
                </div>
              </div>
            </div>

            <div className={classNames(styles.onecontent)}>
              <div className={classNames(styles.infoFormat)}>
                <p>
                  {t("foil_roll_size")}
                  {i18n.language === "fr" ? " :" : ":"}
                </p>
                <div
                  className={classNames(styles.informationsbutton)}
                  data-title-info={t("foil_roll_size_info")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                  >
                    <path
                      id="infos"
                      d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                      transform="translate(-4 -4)"
                    />
                  </svg>
                </div>
              </div>
              <div className={classNames(styles.sides)}>
                <div className={classNames(styles.sideA)}>
                  <input
                    className={classNames(styles.paddinginput)}
                    type="text"
                    name="foilC"
                    id="foilC"
                    defaultValue={foilRollSize}
                    onChange={(e) => {
                      setFoilRollSize(parseFloat(e.target.value));
                    }}
                  />
                  <span className={classNames(styles.unite)}>mm</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default CompactCosts;
