import React from "react";
import classNames from "classnames";
import styles from "./style/chooseEquipment.module.scss";
import {
  MachineModelType,
  mapMachineToImage,
} from "@mgi-labs/lib-liste-machine";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChooseEquipment = ({
  machineTypes,
  setMachineType,
}: {
  machineTypes: MachineModelType[] | undefined;
  setMachineType: React.Dispatch<MachineModelType | undefined>;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className={classNames(styles.choosemodal)}>
        <div className={classNames(styles.choosezone)}>
          {machineTypes && machineTypes?.length > 0 ? (
            <div className={classNames(styles.choosemodalcontent)}>
              <div className={classNames(styles.title)}>
                {t("chooseEquipment")}
              </div>
              {machineTypes?.map((m) => (
                <div
                  className={classNames(styles.equipmentElement)}
                  onClick={() => {
                    navigate(`/${m}/list/`);
                    setMachineType(m);
                  }}
                >
                  <img
                    className={classNames(styles.logoMachine)}
                    src={mapMachineToImage(m)}
                    alt={"m"}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={classNames(styles.title)}>{t("noEquipment")}</div>
          )}
        </div>
      </div>
    </>
  );
};
export default ChooseEquipment;
