import React, { useState } from "react";
import { formatNumber } from "./calculator/calculator";
import classNames from "classnames";
import styles from "./style/dashboard.module.scss";
import { useTranslation } from "react-i18next";
import { Devise } from "../calculatorTypes";
import expandLess from "../img/expand_less.svg";
import expandMore from "../img/expand_more.svg";
import image from "../img/image.svg";
import { RoiResult } from "./calculator/roiResult";
import { PdfPreflightResponse } from "../pdfPreflight.interface";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const TableFoilCost = ({
  devise,
  foil,
  roiCostPrint,
  preflight,
  machineType,
}: {
  devise: Devise;
  foil: string;
  roiCostPrint: RoiResult;
  preflight: PdfPreflightResponse[] | undefined;
  machineType: MachineModelType | undefined;
}) => {
  const { t } = useTranslation();

  const [isOpenFoilDetail, setIsOpenFoilDetail] = useState<boolean>(false);

  const urls = preflight
    ?.map((p) => p.foils?.filter((f) => f.name === foil)?.map((e) => e.url))
    .flat()
    .filter((x) => x !== undefined);

  return (
    <>
      {isOpenFoilDetail && (
        <>
          <tr key={foil}>
            <td>
              {t(machineType === "ALPHAJET" ? "foilVarnish" : "clearVarnish", {
                foil: foil,
              })}
            </td>

            <td className={classNames(styles.foilSplit)}>
              {formatNumber(
                roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                  ?.varnish ?? 0,
                6
              )}{" "}
              {devise}
            </td>
            <td className={classNames(styles.foilSplit)}>
              {formatNumber(
                roiCostPrint.costPerPage.foilCosts.find((f) => f.name === foil)
                  ?.varnish ?? 0,
                6
              )}{" "}
              {devise}
            </td>
            <td className={classNames(styles.foilSplit)}>
              {formatNumber(
                roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                  ?.varnish ?? 0,
                6
              )}{" "}
              {devise}
            </td>
          </tr>
          <tr>
            <td>
              {t(machineType === "ALPHAJET" ? "foilFilm" : "compactFoilFilm", {
                foil: foil,
              })}
            </td>

            <td className={classNames(styles.foilSplit)}>
              {formatNumber(
                roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                  ?.film ?? 0,
                6
              )}{" "}
              {devise}
            </td>
            <td className={classNames(styles.foilSplit)}>
              {formatNumber(
                roiCostPrint.costPerPage.foilCosts.find((f) => f.name === foil)
                  ?.film ?? 0,
                6
              )}{" "}
              {devise}
            </td>
            <td
              className={classNames(styles.foilSplit)}
              style={{ position: "relative" }}
            >
              {formatNumber(
                roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                  ?.film ?? 0,
                6
              )}{" "}
              {devise}
              {urls && urls?.length > 0 && machineType === "ALPHAJET" && (
                <div className={classNames(styles.expand)}>
                  <a href={urls?.[0] ?? ""} target="_black" rel="noreferrer">
                    <img src={image} alt={"expand"} />
                  </a>
                </div>
              )}
            </td>
          </tr>
        </>
      )}
      <tr>
        <td>{machineType === "ALPHAJET" ? `Total ${foil}` : t("Foil")}</td>
        <td>
          {formatNumber(
            roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
              ?.total ?? 0,
            6
          )}{" "}
          {devise}
        </td>
        <td>
          {formatNumber(
            roiCostPrint.costPerPage.foilCosts.find((f) => f.name === foil)
              ?.total ?? 0,
            6
          )}{" "}
          {devise}
        </td>

        <td style={{ position: "relative" }}>
          {formatNumber(
            roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
              ?.total ?? 0,
            6
          )}{" "}
          {devise}
          <div
            className={classNames(styles.expand)}
            onClick={() => setIsOpenFoilDetail(!isOpenFoilDetail)}
          >
            <img
              src={isOpenFoilDetail ? expandLess : expandMore}
              alt={"expand"}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableFoilCost;
