import { Auth } from "@mgi-labs/mgi-id";
import { Machine } from "../components/Domain/machine.interface";

export default class MachineBackend {
  constructor(
    public readonly backendUrl: string,
    private readonly auth: Auth,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}

  async getMachines(): Promise<Machine[]> {
    const url = this.expandURL(`/machines/machinesAndStatus/`);
    const response = await this.fetchWithAuth(url);

    if (!response.ok) {
      throw new Error("Could not get machines from server");
    }
    const obj = await response.json();
    const json = obj.machines as Machine[];

    return json;
  }

  private expandURL(path: string): string {
    return this.backendUrl !== ""
      ? new URL(path, this.backendUrl).toString()
      : path;
  }

  private async fetchWithAuth(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response> {
    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", `Bearer ${this.auth.token}`);
    return await this.fetch(input, init);
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
  }
}
