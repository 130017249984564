import { useUser } from "@mgi-labs/mgi-id";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCalculator } from "../hooks/useCalculator";
import { OptimizedFoilUsagePerSheet } from "./calculator/staticInput";
import { StaticValueOfParameters } from "./calculator/staticInput";
import Coverage from "./BigCostCalculator";
import {
  CalculatorConfig,
  CompactAssociation,
  CostsType,
  CoverageType,
  defaultConfig,
  defaultConso,
  defaultCosts,
  defaultCoverage,
  Devise,
  OpvType,
} from "../calculatorTypes";
import { ConsoPerFace } from "./calculator/roiResult";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

function CalculatorFetch({
  setMachineType,
}: {
  setMachineType: React.Dispatch<MachineModelType>;
}) {
  const location = useLocation();
  const id =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const { data: calculator, mutate } = useCalculator(parseInt(id));

  useEffect(() => {
    mutate();
  }, [id, mutate]);

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  useEffect(() => {
    machineType && setMachineType(machineType);
  }, [machineType, setMachineType]);

  const [loaded, setLoaded] = useState<boolean>(false);

  const profile = useUser();

  const [varnishA, setVarnishA] = useState<number>(
    StaticValueOfParameters.front.embellishmentUv2d3dEffect
  );
  const [foilA, setFoilA] = useState<number>(
    StaticValueOfParameters.front.digitalHotFoil2d3dEffect
  );
  const [trueFoil, setTrueFoil] = useState<number>(
    StaticValueOfParameters.front.digitalTrueHotFoil2d3dEffect ?? 7
  );

  const [opvA, setOpvA] = useState<boolean>(StaticValueOfParameters.front.opv);
  const [opvA2d3d, setOpvA2d3d] = useState<number>(
    StaticValueOfParameters.front.opv2d3d
  );

  const [varnishB, setVarnishB] = useState<number>(
    StaticValueOfParameters.back.embellishmentUv2d3dEffect
  );
  const [foilB, setFoilB] = useState<number>(
    StaticValueOfParameters.back.embellishmentUv2d3dEffect
  );
  const [opvB, setOpvB] = useState<boolean>(StaticValueOfParameters.back.opv);
  const [opvB2d3d, setOpvB2d3d] = useState<number>(
    StaticValueOfParameters.back.opv2d3d
  );

  const [antifoil, setAntifoil] = useState<number>(
    StaticValueOfParameters.front.antifoil2d3dEffect ?? 7
  );

  const [inkCost, setInkCost] = useState<number>(
    StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerInkLiter
  );
  const [varnishCost, setVarnishCost] = useState<number>(
    machineType === "ALPHAJET"
      ? StaticValueOfParameters.alphaJetGlobalPrintingCosts
          .costPerUvVarnishLiter
      : 72
  );
  const [antifoilCost, setAntifoilCost] = useState<number>(
    StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerAntifoilLiter
  );
  const [foilCost, setFoilCost] = useState<number>(
    StaticValueOfParameters.sqmPrice
  );
  const [primerCost, setPrimerCost] = useState<number>(
    StaticValueOfParameters.primerCost
  );
  const [paperCost, setPaperCost] = useState<number>(
    StaticValueOfParameters.paperCostForJob
  );

  const [foilInfoA, setFoilInfoA] = useState<OptimizedFoilUsagePerSheet>(
    StaticValueOfParameters.front.optimizedFoilUsagePerSheet
  );
  const [foilInfoB, setFoilInfoB] = useState<OptimizedFoilUsagePerSheet>(
    StaticValueOfParameters.back.optimizedFoilUsagePerSheet
  );

  const [foilRollSize, setFoilRollSize] = useState<number>(320);

  const [devise, setDevise] = useState<Devise>("€");

  const [fileName, setFileName] = useState<string>("");

  const [finalHeight, setFinalHeight] = useState<number>(1020);
  const [finalWidth, setFinalWidth] = useState<number>(720);

  const [imageHeight, setImageHeight] = useState<number>(1020);
  const [imageWidth, setImageWidth] = useState<number>(720);

  const [upsPerSheet, setUpsPerSheet] = useState<number>(1);
  const [sheetNumber, setSheetNumber] = useState<number>(1);

  const [costs, setCosts] = useState<CostsType>(defaultCosts);
  const [coverage, setCoverage] = useState<CoverageType>(defaultCoverage);
  const [compactVarnishAssociation, setCompactVarnishAssociation] =
    useState<CompactAssociation>();

  const [conso, setConso] = useState<ConsoPerFace>(defaultConso);

  const [opvType, setOpvType] = useState<OpvType>("spot");

  const [noPdfProject, setNoPdfProject] = useState<boolean>(false);

  const [localPdfValues, setLocalPdfValues] = useState<{
    [value: string]: number;
  }>();

  const [config, setConfig] = useState<CalculatorConfig>(defaultConfig);

  useEffect(() => {
    if (isNaN(parseInt(id))) {
      setCosts(defaultCosts);
      setConso(defaultConso);
      setCoverage(defaultCoverage);
      setLoaded(true);
      setVarnishA(StaticValueOfParameters.front.embellishmentUv2d3dEffect);
      setAntifoil(StaticValueOfParameters.front.antifoil2d3dEffect ?? 7);
      setFoilA(StaticValueOfParameters.front.digitalHotFoil2d3dEffect);
      setOpvA(StaticValueOfParameters.front.opv);
      setOpvA2d3d(StaticValueOfParameters.front.opv2d3d);

      setVarnishB(StaticValueOfParameters.back.embellishmentUv2d3dEffect);
      setFoilB(StaticValueOfParameters.back.embellishmentUv2d3dEffect);
      setOpvB(StaticValueOfParameters.back.opv);
      setOpvA2d3d(StaticValueOfParameters.back.opv2d3d);

      setInkCost(
        StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerInkLiter
      );
      setFoilCost(StaticValueOfParameters.sqmPrice);
      setFoilRollSize(StaticValueOfParameters.foilRollSize);
      setVarnishCost(
        machineType === "ALPHAJET"
          ? StaticValueOfParameters.alphaJetGlobalPrintingCosts
              .costPerUvVarnishLiter
          : 72
      );
      setAntifoilCost(
        StaticValueOfParameters.alphaJetGlobalPrintingCosts.costPerAntifoilLiter
      );
      setPaperCost(StaticValueOfParameters.paperCostForJob);
      setPrimerCost(StaticValueOfParameters.primerCost);
      setDevise("€");

      if (machineType === "ALPHAJET") {
        setFinalHeight(1020);
        setFinalWidth(720);
        setImageWidth(720);
        setImageHeight(1020);
      } else {
        setFinalHeight(50);
        setFinalWidth(100);
        setImageWidth(100);
        setImageHeight(50);
      }
      setUpsPerSheet(1);
      setSheetNumber(1);

      setFoilInfoA(StaticValueOfParameters.front.optimizedFoilUsagePerSheet);

      setFoilInfoB(StaticValueOfParameters.back.optimizedFoilUsagePerSheet);

      setFileName("");

      setCosts(defaultCosts);

      setOpvType("spot");

      setCompactVarnishAssociation(undefined);

      setLocalPdfValues(undefined);

      setConfig(defaultConfig);

      setLoaded(true);
    } else if (!(typeof calculator === "string")) {
      if (parseInt(id) === calculator?.id) {
        if (calculator) {
          setImageWidth(calculator.data.visuSize.width);
          setImageHeight(calculator.data.visuSize.height);

          setVarnishA(calculator.data.infos.front.varnish);
          setAntifoil(calculator.data.infos.front.antifoil);
          setFoilA(calculator.data.infos.front.foil);
          setOpvA(calculator.data.infos.front.opv);
          setOpvA2d3d(calculator.data.infos.front.opv2d3d);

          setVarnishB(calculator.data.infos.back.varnish);
          setFoilB(calculator.data.infos.back.foil);
          setOpvB(calculator.data.infos.back.opv);
          setOpvA2d3d(calculator.data.infos.back.opv2d3d);

          setInkCost(calculator.data.costs.cmykCost);
          setFoilCost(calculator.data.costs.foilCost);
          setFoilRollSize(calculator.data.costs.foilRollSize);
          setVarnishCost(calculator.data.costs.varnishCost);
          setAntifoilCost(calculator.data.costs.antifoilCost);
          setPaperCost(calculator.data.costs.substratCost);
          setPrimerCost(calculator.data.costs.primerCost);
          setDevise(calculator.data.costs.devise);

          setFinalHeight(calculator.data.output.size.height);
          setFinalWidth(calculator.data.output.size.width);
          setUpsPerSheet(calculator.data.output.upsPerSheet);
          setSheetNumber(calculator.data.output.copyNumber);

          calculator.data.infos.front.optimizedFoilUsagePerSheet &&
            setFoilInfoA(
              calculator.data.infos.front.optimizedFoilUsagePerSheet
            );
          calculator.data.infos.back.optimizedFoilUsagePerSheet &&
            setFoilInfoB(calculator.data.infos.back.optimizedFoilUsagePerSheet);

          setFileName(calculator.name);

          setCosts(calculator.data.allCosts);

          setOpvType(noPdfProject ? "file" : calculator.data.opvType ?? "spot");

          setCompactVarnishAssociation(calculator.data.association);

          setLoaded(true);

          setNoPdfProject(calculator.data.noPdfProject);

          setLocalPdfValues(calculator.data.noPdfValues);

          setConfig(calculator.data.config ?? defaultConfig);
        }
      }
    }
  }, [id, profile?.email, calculator, noPdfProject, machineType]);

  useEffect(() => {
    if (noPdfProject) {
      setOpvType("file");
      setCompactVarnishAssociation({
        varnish: "foil",
        antifoil: "varnish",
        foil: "trueFoil",
      });
      setFileName(fileName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPdfProject]);

  const [firstSave, setFirstSave] = useState<boolean>(false);

  return loaded ? (
    <Coverage
      calculator={calculator === "Error" ? undefined : calculator}
      //setAllInputsId={setAllInputsId}
      setVarnishA={setVarnishA}
      varnishA={varnishA}
      antifoil={antifoil}
      setAntifoil={setAntifoil}
      setFoilA={setFoilA}
      foilA={foilA}
      setTrueFoil={setTrueFoil}
      trueFoil={trueFoil}
      setOpvA={setOpvA}
      opvA={opvA}
      setVarnishB={setVarnishB}
      varnishB={varnishB}
      setFoilB={setFoilB}
      foilB={foilB}
      setOpvB={setOpvB}
      opvB={opvB}
      setInkCost={setInkCost}
      inkCost={inkCost}
      setFoilCost={setFoilCost}
      foilCost={foilCost}
      setVarnishCost={setVarnishCost}
      setAntifoilCost={setAntifoilCost}
      varnishCost={varnishCost}
      antifoilCost={antifoilCost}
      setPaperCost={setPaperCost}
      paperCost={paperCost}
      setPrimerCost={setPrimerCost}
      primerCost={primerCost}
      devise={devise}
      setDevise={setDevise}
      setFinalHeight={setFinalHeight}
      finalHeight={finalHeight}
      setFinalWidth={setFinalWidth}
      finalWidth={finalWidth}
      setUpsPerSheet={setUpsPerSheet}
      upsPerSheet={upsPerSheet}
      setSheetNumber={setSheetNumber}
      sheetNumber={sheetNumber}
      mutate={mutate}
      fileName={fileName}
      setFileName={setFileName}
      firstSave={firstSave}
      setFirstSave={setFirstSave}
      costs={costs}
      setCosts={setCosts}
      coverage={coverage}
      setCoverage={setCoverage}
      conso={conso}
      setConso={setConso}
      foilInfoA={foilInfoA}
      foilInfoB={foilInfoB}
      setFoilInfoA={setFoilInfoA}
      setFoilInfoB={setFoilInfoB}
      opvType={opvType}
      setOpvType={setOpvType}
      opvA2d3d={opvA2d3d}
      setOpvA2d3d={setOpvA2d3d}
      opvB2d3d={opvB2d3d}
      setOpvB2d3d={setOpvB2d3d}
      compactVarnishAssociation={compactVarnishAssociation}
      setCompactVarnishAssociation={setCompactVarnishAssociation}
      setLoaded={setLoaded}
      noPdfProject={noPdfProject}
      setNoPdfProject={setNoPdfProject}
      localPdfValues={localPdfValues}
      setLocalPdfValues={setLocalPdfValues}
      setFoilRollSize={setFoilRollSize}
      foilRollSize={foilRollSize}
      setImageHeight={setImageHeight}
      imageHeight={imageHeight}
      setImageWidth={setImageWidth}
      imageWidth={imageWidth}
      config={config}
      setConfig={setConfig}
    />
  ) : null;
}

export default CalculatorFetch;
