import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/choosemodal.module.scss";
import { useTranslation } from "react-i18next";
import { CalculatorConfig } from "../calculatorTypes";

const ChoosemodalCompactNoPdf = ({
  setShowModal,
  setNoPdfStart,
  handleCancelUpload,
  config,
  setConfig,
}: {
  setShowModal: React.Dispatch<boolean>;
  setNoPdfStart: React.Dispatch<boolean>;
  handleCancelUpload: () => void;
  config: CalculatorConfig;
  setConfig: React.Dispatch<CalculatorConfig>;
}) => {
  const { t } = useTranslation();

  const [localConfig, setLocalConfig] = useState<CalculatorConfig>(config);

  const handleApplyLayers = useCallback(async () => {
    setConfig(localConfig);
    setShowModal(false);
    setNoPdfStart(false);
  }, [localConfig, setConfig, setNoPdfStart, setShowModal]);

  return (
    <>
      {/* class displaynone pour cacher la modal */}
      <div className={classNames(styles.choosemodal)}>
        <div className={classNames(styles.closezone)}></div>
        <div className={classNames(styles.choosezone)}>
          <div className={classNames(styles.choosecase)}>
            <p className={classNames(styles.chooseModuleText)}>
              {t("select_modules")}
            </p>
            <div className={classNames(styles.checkboxContainer)}>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option1"
                  checked={localConfig.printer1}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      printer1: e.target.checked,
                    });
                    setConfig({ ...localConfig, printer1: e.target.checked });
                  }}
                />
                <label htmlFor="option1">
                  Printer 1 <br />
                  (Clear Varnish)
                </label>
              </div>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option2"
                  checked={localConfig.printer2}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      printer2: e.target.checked,
                    });
                    setConfig({ ...localConfig, printer2: e.target.checked });
                  }}
                />
                <label htmlFor="option2">
                  Printer 2 <br />
                  (Anti Foil Varnish)
                </label>
              </div>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option3"
                  checked={localConfig.ifoil}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      ifoil: e.target.checked,
                    });
                    setConfig({ ...localConfig, ifoil: e.target.checked });
                  }}
                />
                <label htmlFor="option3">
                  iFoil <br />
                  (Hot Foiling)
                </label>
              </div>
            </div>
          </div>

          <div className={classNames(styles.validate)}>
            <div
              className={classNames(styles.button)}
              onClick={handleCancelUpload}
            >
              <p>{t("cancel")}</p>
            </div>
            <div
              className={classNames(styles.button)}
              onClick={() => handleApplyLayers()}
            >
              <p>{t("apply")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChoosemodalCompactNoPdf;
