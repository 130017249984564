import React from "react";
import { formatNumber } from "./calculator/calculator";
import { useTranslation } from "react-i18next";
import { CalculatorConfig, Devise, mapConfigToCase } from "../calculatorTypes";
import { RoiResult } from "./calculator/roiResult";

const TableFoilCostCompact = ({
  devise,
  foil,
  roiCostPrint,
  config,
}: {
  devise: Devise;
  foil: string;
  roiCostPrint: RoiResult;
  config: CalculatorConfig;
}) => {
  const { t } = useTranslation();

  const configCase = mapConfigToCase(config);

  return (
    <>
      <>
        {(configCase === "case2" ||
          configCase === "case4" ||
          configCase === "case5") && (
          <tr key={foil + "foil"}>
            <td>
              {t(configCase === "case4" ? "clearVarnishFoil" : "clearVarnish", {
                foil: foil,
              })}
            </td>

            <td>
              {formatNumber(roiCostPrint.costPerVisu.costofVarnishUsed ?? 0, 8)}{" "}
              {devise}
            </td>

            <td>
              {formatNumber(roiCostPrint.totalCost.costofVarnishUsed ?? 0, 8)}{" "}
              {devise}
            </td>
          </tr>
        )}

        {(configCase === "case2" ||
          configCase === "case3" ||
          configCase === "case1") && (
          <tr key={foil + "truefoil"}>
            <td>
              {t(
                configCase === "case2" || configCase === "case3"
                  ? "clearVarnishFoil"
                  : "clearVarnish",
                {
                  foil: foil,
                }
              )}
            </td>

            <td>
              {formatNumber(
                roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                  ?.varnish ?? 0,
                8
              )}{" "}
              {devise}
            </td>

            <td>
              {formatNumber(
                roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                  ?.varnish ?? 0,
                8
              )}{" "}
              {devise}
            </td>
          </tr>
        )}
        {config.ifoil && (
          <tr key={"ifoil"}>
            <td>
              {t("compactFoilFilm", {
                foil: foil,
              })}
            </td>

            <td>
              {formatNumber(
                roiCostPrint.costPerVisu.foilCosts.find((f) => f.name === foil)
                  ?.film ?? 0,
                8
              )}{" "}
              {devise}
            </td>

            <td style={{ position: "relative" }}>
              {formatNumber(
                roiCostPrint.totalCost.foilCosts.find((f) => f.name === foil)
                  ?.film ?? 0,
                8
              )}{" "}
              {devise}
            </td>
          </tr>
        )}
      </>
    </>
  );
};

export default TableFoilCostCompact;
