import React from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { OPV_TYPE, OpvType } from "../calculatorTypes";
import { PdfPreflight } from "../pdfPreflight.interface";

const SELECT_VALUES = [0, 3, 7, 14, 28, 35, 42, 56, 63, 77, 112];
const SELECT_VALUES_OPV = [0, 3, 6, 9, 12, 15];

const AlphajetSettings = ({
  currentPreflight,
  machineType,
  doubleSided,
  alphaJetSpeed,
  opvType,
  setOpvType,
  hasVarnish,
  hasFoil,
  varnishA,
  setVarnishA,
  varnishB,
  setVarnishB,
  foilA,
  setFoilA,
  foilB,
  setFoilB,
  opvA,
  setOpvA,
  opvB,
  setOpvB,
  opvA2d3d,
  setOpvA2d3d,
  opvB2d3d,
  setOpvB2d3d,
}: {
  currentPreflight: PdfPreflight;
  machineType: string;
  doubleSided: boolean;
  alphaJetSpeed: number;
  opvType: OpvType;
  setOpvType: React.Dispatch<OpvType>;
  hasVarnish: boolean | undefined;
  hasFoil: boolean;
  varnishA: number;
  setVarnishA: React.Dispatch<number>;
  varnishB: number;
  setVarnishB: React.Dispatch<number>;
  foilA: number;
  setFoilA: React.Dispatch<number>;
  foilB: number;
  setFoilB: React.Dispatch<number>;
  opvA: boolean;
  setOpvA: React.Dispatch<boolean>;
  opvB: boolean;
  setOpvB: React.Dispatch<boolean>;
  opvA2d3d: number;
  setOpvA2d3d: React.Dispatch<number>;
  opvB2d3d: number;
  setOpvB2d3d: React.Dispatch<number>;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={classNames(styles.onecontainer)}>
      <div className={classNames(styles.informations)}>
        <div className={classNames(styles.titlezone)}>
          <p className={classNames(styles.title)}>
            {t("settings")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            {doubleSided && (
              <>
                <p>{t("front")}</p>
                <p>{t("back")}</p>
              </>
            )}
          </div>
        </div>
        {hasVarnish && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("varnish2d3D")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div
                className={classNames(
                  doubleSided ? styles.sideA : styles.sideB
                )}
              >
                <select
                  className={classNames(styles.select)}
                  name="varnishA"
                  id="varnishA"
                  defaultValue={varnishA}
                  onChange={(e) => {
                    setVarnishA(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
              {doubleSided && (
                <div className={classNames(styles.sideB)}>
                  <select
                    className={classNames(styles.select)}
                    name="varnishB"
                    id="varnishB"
                    defaultValue={varnishB}
                    onChange={(e) => {
                      setVarnishB(parseFloat(e.target.value));
                    }}
                  >
                    {SELECT_VALUES.map((v) => (
                      <option value={v} key={v}>
                        {v} µm
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        )}

        {hasFoil && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("foil2d3D")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div
                className={classNames(
                  doubleSided ? styles.sideA : styles.sideB
                )}
              >
                <select
                  className={classNames(styles.select)}
                  name="foilA"
                  id="foilA"
                  defaultValue={foilA}
                  onChange={(e) => {
                    setFoilA(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
              {doubleSided && (
                <div className={classNames(styles.sideB)}>
                  <select
                    className={classNames(styles.select)}
                    name="foilB"
                    id="foilB"
                    defaultValue={foilB}
                    onChange={(e) => {
                      setFoilB(parseFloat(e.target.value));
                    }}
                  >
                    {SELECT_VALUES.map((v) => (
                      <option value={v} key={v}>
                        {v} µm
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        )}

        <div className={classNames(styles.onecontent)}>
          <p>
            {t("opv")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div
              className={classNames(doubleSided ? styles.sideA : styles.sideB)}
            >
              <input
                className={classNames(styles.paddinginput2)}
                type="checkbox"
                name="opvA"
                id="opvA"
                checked={opvA}
                onChange={(e) => {
                  setOpvA(e.target.checked);
                }}
              />
            </div>
            {doubleSided && (
              <div className={classNames(styles.sideB)}>
                <input
                  className={classNames(styles.paddinginput2)}
                  type="checkbox"
                  name="opvB"
                  id="opvB"
                  checked={opvB}
                  onChange={(e) => {
                    setOpvB(e.target.checked);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("opvType")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div className={classNames(styles.sideA)}>
                <select
                  className={classNames(styles.select)}
                  name="opvType"
                  id="opvType"
                  defaultValue={opvType}
                  onChange={(e) => {
                    setOpvType(e.target.value);
                  }}
                >
                  {OPV_TYPE.map(
                    (type) =>
                      ((type === "file" &&
                        currentPreflight?.pdfPreflightResponse
                          ?.flat()
                          .some((n) =>
                            n?.otherColor.some((m) =>
                              m.name.toUpperCase().includes("OPV")
                            )
                          )) ||
                        type !== "file") && (
                        <option value={type} key={type}>
                          {type}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
        }

        <div className={classNames(styles.onecontent)}>
          <p>
            {t("opv2d3D")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div
              className={classNames(doubleSided ? styles.sideA : styles.sideB)}
            >
              <select
                className={classNames(styles.select)}
                name="opvA2d3d"
                id="opvA2d3d"
                defaultValue={opvA2d3d}
                onChange={(e) => {
                  setOpvA2d3d(parseFloat(e.target.value));
                }}
              >
                {SELECT_VALUES_OPV.map((v) => (
                  <option value={v} key={v}>
                    {v} µm
                  </option>
                ))}
              </select>
            </div>
            {doubleSided && (
              <div className={classNames(styles.sideB)}>
                <select
                  className={classNames(styles.select)}
                  name="opvB2d3d"
                  id="opvB2d3d"
                  defaultValue={opvB2d3d}
                  onChange={(e) => {
                    setOpvB2d3d(parseFloat(e.target.value));
                  }}
                >
                  {SELECT_VALUES_OPV.map((v) => (
                    <option value={v} key={v}>
                      {v} µm
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        <div className={classNames(styles.onecontent, styles.ontwoline)}>
          <p className={classNames(styles.longText)}>
            {t("printingSpeed", { machineType })}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <div className={classNames(styles.sideB)}>
                <p
                  className={classNames(styles.paddinginput3)}
                  id="printingSpeed"
                >
                  {alphaJetSpeed}/h
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AlphajetSettings;
