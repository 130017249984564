import { OptimizedFoilUsagePerSheet } from "./components/calculator/staticInput";
import { PdfPreflight } from "./pdfPreflight.interface";

export type Calculator = {
  data: AllInputs;
  name: string;
  id: number;
  userName: string;
  created: Date;
  modified: Date;
};

export type FaceParams = {
  opv: boolean;
  varnish: number;
  foil: number;
  opv2d3d: number;
  optimizedFoilUsagePerSheet: OptimizedFoilUsagePerSheet;
  trueFoil?: number;
};

type OneCost = {
  costOfMaterial: number;
  costofCMYKPrint: number;
  costofVarnishUsed: number;
  costofAntifoilUsed?: number;
  finalOpvCost: number;
  foilCosts: {
    name: string;
    film: number;
    varnish: number;
    total: number;
  }[];
  totalCostofJob: number;
};

export const defaultCoverage: CoverageType = {
  front: {
    cmyk: {
      cyan: { percent: 0, ml: 0 },
      magenta: { percent: 0, ml: 0 },
      yellow: { percent: 0, ml: 0 },
      black: { percent: 0, ml: 0 },
    },
    varnish: { percent: 0, ml: 0 },
    foil: [{ percent: 0, ml: 0 }],
    opv: { percent: 0, ml: 0 },
    antifoil: { percent: 0, ml: 0 },
  },
  back: {
    cmyk: {
      cyan: { percent: 0, ml: 0 },
      magenta: { percent: 0, ml: 0 },
      yellow: { percent: 0, ml: 0 },
      black: { percent: 0, ml: 0 },
    },
    varnish: { percent: 0, ml: 0 },
    foil: [{ percent: 0, ml: 0 }],
    opv: { percent: 0, ml: 0 },
  },
};

export const OPV_TYPE = ["file", "spot", "full"];

export type OpvType = (typeof OPV_TYPE)[number];

export const defaultCosts: CostsType = {
  visu: {
    costOfMaterial: 0,
    costofCMYKPrint: 0,
    costofVarnishUsed: 0,
    finalOpvCost: 0,
    foilCosts: [],
    totalCostofJob: 0,
  },
  page: {
    costOfMaterial: 0,
    costofCMYKPrint: 0,
    costofVarnishUsed: 0,
    finalOpvCost: 0,
    foilCosts: [],
    totalCostofJob: 0,
  },
  total: {
    costOfMaterial: 0,
    costofCMYKPrint: 0,
    costofVarnishUsed: 0,
    finalOpvCost: 0,
    foilCosts: [],
    totalCostofJob: 0,
  },
  sqmPrice: 0,
};

export const defaultConso = {
  front: {
    cmyk: 0,
    varnish: 0,
    antifoil: 0,
    foil: [{ name: "goldFoil", value: 0 }],
    opv: 0,
  },
  back: { cmyk: 0, varnish: 0, foil: [{ name: "goldFoil", value: 0 }], opv: 0 },
};

export type CostsType = {
  visu: OneCost;
  page: OneCost;
  total: OneCost;
  sqmPrice: number;
};

type ColorCoverage = {
  percent: number;
  ml: number;
};

type CMYKCoverage = {
  cyan: ColorCoverage;
  magenta: ColorCoverage;
  yellow: ColorCoverage;
  black: ColorCoverage;
};

export type CoverageType = {
  front: {
    cmyk: CMYKCoverage;
    varnish: ColorCoverage;
    foil: ColorCoverage[];
    opv: ColorCoverage;
    antifoil: ColorCoverage;
  };
  back: {
    cmyk: CMYKCoverage;
    varnish: ColorCoverage;
    foil: ColorCoverage[];
    opv: ColorCoverage;
  };
};

export type AllInputs = {
  visuSize: {
    width: number;
    height: number;
  };
  output: {
    size: {
      width: number;
      height: number;
    };
    upsPerSheet: number;
    copyNumber: number;
  };
  infos: {
    front: FaceParams;
    back: FaceParams;
  };
  costs: {
    devise: Devise;
    cmykCost: number;
    varnishCost: number;
    foilCost: number;
    substratCost: number;
    foilRollSize: number;
    sqmPrice: number;
    antifoilCost: number;
  };

  allCosts: CostsType;
  coverage: CoverageType;

  pdfPreflight: PdfPreflight | undefined;
  doubleSided: boolean;
  foilSurface: number;
  config?: { printer1: boolean; printer2: boolean; ifoil: boolean };
};

export type Devise = "€" | "$" | "£";

export interface CalculatorConfig {
  printer1: boolean;
  printer2: boolean;
  ifoil: boolean;
}

export const defaultConfig: CalculatorConfig = {
  printer1: false,
  printer2: false,
  ifoil: false,
};

export function mapConfigToCase(config: CalculatorConfig) {
  if (config.printer1 && !config.printer2 && !config.ifoil) {
    return "case1";
  }
  if (config.printer1 && !config.printer2 && config.ifoil) {
    return "case2";
  }
  if (!config.printer1 && !config.printer2 && config.ifoil) {
    return "case3";
  }
  if (config.printer1 && config.printer2 && config.ifoil) {
    return "case4";
  }
  if (config.printer1 && config.printer2 && !config.ifoil) {
    return "case5";
  }
  if (!config.printer1 && config.printer2 && !config.ifoil) {
    return "case6";
  }
}

export type CompactAssociation = {
  varnish: string | undefined;
  antifoil: string | undefined;
  foil?: string;
};
