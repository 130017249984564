import { Auth } from "@mgi-labs/mgi-id";
import { AllInputs, Calculator, OpvType } from "../calculatorTypes";
import { defaultRoi } from "../components/calculator/defaultRoi";
import { OtherCover } from "../pdfPreflight.interface";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

function round(num: number) {
  return Math.round(num * 100) / 100;
}

export default class Backend {
  constructor(
    public readonly rootUrl: string,
    public readonly auth: Auth,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}
  async fetchWithAuth(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response> {
    await this.auth?.refreshSession();
    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", `Bearer ${this.auth?.token}`);
    return await this.fetch(input, init);
  }

  async addCostCalculator(
    allInputs: AllInputs,
    name: string,
    machineType: MachineModelType
  ): Promise<Calculator> {
    const url = this.expandURL("/calculator");
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: allInputs,
        name: name,
        machineType: machineType,
      }),
    });
    const dto = await response.json();
    return dto;
  }

  async linkToRoi(
    allInputs: AllInputs,
    name: string,
    opvType: OpvType,
    opvPercent: OtherCover[] | undefined
  ): Promise<Calculator> {
    const url = new URL(
      "/projects",
      process.env.REACT_APP_ROI_BACKEND_URL
    ).toString();
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          ...defaultRoi,
          foilSurface: allInputs.foilSurface,
          roiInfo: { roiName: name, roiDesc: "" },
          calibrationInputs: {
            ...defaultRoi.calibrationInputs,
            sqmPrice: allInputs.costs.foilCost ?? 0.45,
            alphaJetGlobalPrintingCosts: {
              ...defaultRoi.calibrationInputs.alphaJetGlobalPrintingCosts,
              costPerUvVarnishLiter: allInputs.costs.varnishCost ?? 73,
              alphaJetGlobalPrintingCost: allInputs.costs.cmykCost ?? 90,
            },
          },
          jobInfoInputs: {
            ...defaultRoi.jobInfoInputs,
            alphaJetInfos: {
              ...defaultRoi.jobInfoInputs.alphaJetInfos,
              substratCostPer1000Sheet: allInputs.costs.substratCost * 1000,
            },
            numberOfSheets:
              Math.ceil(
                allInputs.output.copyNumber / allInputs.output.upsPerSheet
              ) ?? 1000,
            upsPerSheet: allInputs.output.upsPerSheet ?? 1,
            paperFormat: {
              width: allInputs.visuSize.width ?? 720,
              length: allInputs.visuSize.height ?? 1020,
            },
            back: {
              ...defaultRoi.jobInfoInputs.back,
              optimizedFoilUsagePerSheet:
                allInputs.infos.back.optimizedFoilUsagePerSheet,
              opv2d3dEffect: allInputs.infos.back.opv2d3d,
              opv: allInputs.infos.back.opv
                ? round(
                    opvType === "file"
                      ? opvPercent?.[1].percent ?? 0
                      : opvType === "spot"
                      ? round(
                          allInputs.coverage.back.cmyk.black.percent +
                            allInputs.coverage.back.cmyk.cyan.percent +
                            allInputs.coverage.back.cmyk.magenta.percent +
                            allInputs.coverage.back.cmyk.yellow.percent ?? 0
                        )
                      : 100 ?? 0
                  )
                : 0,
              digitalHotFoil2d3dEffect: allInputs.infos.back.foil ?? 0,
              digitalHotFoilCoverage: round(
                allInputs.coverage.back.foil.reduce(
                  (a, b) => a + parseFloat(b.percent.toString()),
                  0
                ) ?? 0
              ),
              embellishmentUv2d3dEffect: allInputs.infos.back.varnish ?? 7,
              embellishmentSpotUvCoverage: round(
                allInputs.coverage.back.varnish.percent ?? 0
              ),
              cmykPrintFileInkCoverage: round(
                allInputs.coverage.back.cmyk.black.percent +
                  allInputs.coverage.back.cmyk.cyan.percent +
                  allInputs.coverage.back.cmyk.magenta.percent +
                  allInputs.coverage.back.cmyk.yellow.percent ?? 0
              ),
            },
            front: {
              ...defaultRoi.jobInfoInputs.front,
              optimizedFoilUsagePerSheet:
                allInputs.infos.front.optimizedFoilUsagePerSheet,
              opv2d3dEffect: allInputs.infos.front.opv2d3d,
              opv: allInputs.infos.front.opv
                ? round(
                    opvType === "file"
                      ? opvPercent?.[0].percent ?? 0
                      : opvType === "spot"
                      ? round(
                          allInputs.coverage.front.cmyk.black.percent +
                            allInputs.coverage.front.cmyk.cyan.percent +
                            allInputs.coverage.front.cmyk.magenta.percent +
                            allInputs.coverage.front.cmyk.yellow.percent ?? 0
                        )
                      : 100 ?? 0
                  )
                : 0,
              digitalHotFoil2d3dEffect: allInputs.infos.front.foil ?? 0,
              digitalHotFoilCoverage: round(
                allInputs.coverage.front.foil.reduce(
                  (a, b) => a + parseFloat(b.percent.toString()),
                  0
                ) ?? 0
              ),
              embellishmentUv2d3dEffect: allInputs.infos.front.varnish ?? 0,
              embellishmentSpotUvCoverage: round(
                allInputs.coverage.front.varnish.percent ?? 0
              ),
              cmykPrintFileInkCoverage: round(
                allInputs.coverage.front.cmyk.black.percent +
                  allInputs.coverage.front.cmyk.cyan.percent +
                  allInputs.coverage.front.cmyk.magenta.percent +
                  allInputs.coverage.front.cmyk.yellow.percent ?? 0
              ),
            },
            doubleSided: allInputs.doubleSided,
            paperCostForJob:
              allInputs.costs.substratCost *
                (allInputs.output.copyNumber / allInputs.output.upsPerSheet) ??
              300,
          },
        },
        name: name,
      }),
    });
    const dto = await response.json();
    return dto;
  }

  async updateCostCalculator(
    id: number,
    fileName: string,
    allInputs: AllInputs
  ) {
    const url = this.expandURL(`/calculator/${id}`);
    return await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: fileName,
        data: allInputs,
      }),
    });
  }

  async getCalculator(id: number) {
    const url = this.expandURL(`/calculator/${id}`);
    const response = await this.fetchWithAuth(url.toString());
    const dto = (await response.json()) as Calculator;
    return dto;
  }

  async getCalculatorList(machineType: MachineModelType) {
    const url = this.expandURL(`/calculator/list/${machineType}`);
    const response = await this.fetchWithAuth(url.toString());
    const dto = (await response.json()) as Calculator[];
    return dto;
  }

  async deleteCalculator(calculatorList: Number[]) {
    const url = this.expandURL(`/calculator`);
    await this.fetchWithAuth(url.toString(), {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(calculatorList),
    });
  }

  private expandURL(url: string): string {
    return this.rootUrl ? new URL(url, this.rootUrl).toString() : url;
  }
}
