export const translate = {
  en: {
    translation: {
      //Account
      newestimation: "New",
      Liste: "Estimates",
      devise: "Currency",

      Equipement: "Equipment",
      ALPHAJET: "ALPHAJET",
      COMPACT: "JV3D WEB 400",

      nomdefichier: "PROJECT NAME",
      taillevisu: "Visual size",
      tailleimage: "PDF size",
      formatsortie: "FORMAT",
      Width: "Width",
      Height: "Length",
      settings: "Settings",
      varnish_thickness: "Varnishes Thicknesses",

      confirm_delete: "Do you really want to delete the selected items?",

      visuelformat: "Visuals number per sheet",
      nbofrequest: "Number of requested sheet",
      nbelementspercopy: "Number of items per PDF",
      nbelements: "Number of items for the cost calculation",
      Roll: "Roll",
      items: "items",
      visualNumber: "Number of visuals",
      copy: "Copy",
      antifoil: "Antifoil",
      varnish2d3D: "Varnish 2D / 3D effect",
      foil2d3D: "Foil 2D / 3D effect",
      opv2d3D: "OPV 2D / 3D effect",
      antifoil2d3d: "Antifoil Led Varnish",
      clearVarnish2d3d: "Clear Varnish",
      opv: "OPV (Protective Varnish)",
      opvType: "OPV type",
      printingSpeed: "{{machineType}} printing speed",
      consumablescosts: "CONSUMABLES COSTS",
      inkcost: "Ink cost / liter",
      varnishcost: "Varnish cost  (UV) / Liter",
      antifoilcost: "Antifoil Led Varnish cost / Liter",
      clearVarnishCost: "Clear Varnish cost / Liter",
      foilcost: "Foil cost / sqm",
      substratecost: "Substrate cost / {{type}}",
      primercost: "Primer cost / Liter",
      sheet: "sheet",
      label: "label",
      sqm: "sqm",
      coveragerate: "COVERAGE RATES",
      sqmPrice: "Sqm cost",
      clear_varnish_coverage: "Clear Varnish coverage rate",
      antifoil_coverage: "Antifoil coverage rate",
      inkConsumption: "mL / {{sheets}} {{type}}",
      inkCons: "mL",
      maximizedWidth: "Maximized Foil width usage-roll(s) width",
      maximizedLength: "Maximized Foil length usage / sheet",
      foilWaste: "Foil waste / Roll Up-&-Down",
      totalLengthWaste: "Total Foil length usage / sheet (incl. waste)",

      betweenHeight: "Value must be between 50 and 980",
      betweenWidth: "Value must be between 100 and 420",

      activated_modules: "Activated modules",
      cyan: "Cyan",
      magenta: "Magenta",
      yellow: "Yellow",
      black: "Black",
      Foil: "Foil",
      spotUv: "Spot UV Varnish",
      clearVarnish: "Clear Varnish",
      clearVarnishFoil: "Clear Varnish (Foil)",
      trueFoil: "Clear Varnish (Foil)",
      ANTIFOIL: "Antifoil Led Varnish",
      Antifoil: "Antifoil Led Varnish",
      foilVarnish: "{{foil}} Varnish",
      foilFilm: "{{foil}} Film",
      compactFoilFilm: "Foil film",
      Varnish: "Varnish",
      Substrate: "Substrate",
      CMYK: "CMYK",
      front: "Front",
      back: "Back",
      Importpdffile: "Import a PDF file",
      no_pdf_file: "No PDF estimation",
      ordraganddrop: "Or drag & drop your file here",
      cout: "Cost",
      Visuel: "Up",
      element: "element",
      Feuille: "Sheet",
      chooseEquipment: "Choose your equipment",
      noEquipment: "You don't have any equipment",

      side: "Side",
      surface: "Surfaces (sqm)",
      surfaceTotal: "Total Surface (sqm)",

      Vernis: "Varnish",
      Dorure: "Foil",

      none: "None",
      detectedElements: "Detected Elements",
      selectedElements:
        "Unchecked elements won't be included in the total color calculation",
      selectFoilLayer: "Select the foil layer",
      selectAntifoilLayer: "Select the PDF layer used for Antifoil Led Varnish",
      selectVarnishLayer: "Select the PDF layer used for Clear Varnish",
      foilLayerText:
        "This layer will be used to compute the zone where foil will be applied",
      apply: "Apply",
      cancel: "Cancel",
      select_at_least: "Assign a layer to proceed",

      add_estimate: "New estimation",
      download: "Download",
      save: "Save",

      item_detail: "Item = label, book, ...",

      //Actions
      delete: "Delete",
      edit: "Edit",
      archive: "Download",
      open_project: "Open project",

      addestimation: "New Estimation",
      RoiLink: "Link to ROI",
      start_no_pdf: "Start a new cost calculation without PDF File",
      start_with_pdf: "Start a new cost calculation with PDF File",
      start: "Start",
      foil_roll_size: "Foil Roll Width",
      foil_roll_size_info: "Standard MGI Roll Width value: 320",

      toasterrorenre: "An error occurred while saving",
      toastenregistrer: "Your estimation has been successfully saved",
      toasterror: "An error occurred",
      toastajour: "Your Estimation has been successfully updated",

      Name: "Name",
      User: "User",
      Created: "Created",
      Modified: "Modified",
      VisualNumber: "Visual Number",
      Total: "Total",

      select_modules:
        "What are the modules you want to activate for this production ?",
      hotfoiltext: "What is the PDF layer used for the Hot Foiling ?",
      antifoiltext: "What is the PDF layer used for the Anti Foil Varnish ?",
      clearVarnishtext: "What is the PDF layer used for the Clear Varnish ?",
      clearVarnishHotFoiltext:
        "What is the PDF layer used for the Clear Varnish and the Hot Foiling ?",
    },
  },

  fr: {
    translation: {
      //Account
      newestimation: "Nouveau",
      Liste: "Estimations",
      devise: "Devise",

      Equipement: "Equipement",

      login_text: "Se connecter",

      nomdefichier: "NOM DU PROJET",
      taillevisu: "Taille du visuel",
      tailleimage: "Taille du PDF",
      formatsortie: "FORMAT",
      Width: "Largeur",
      Height: "Longueur",
      settings: "Paramètres",
      varnish_thickness: "Épaisseur des Vernis",

      confirm_delete:
        "Voulez-vous vraiment supprimer les éléments sélectionnés ?",

      visuelformat: "Nombre de visuels par feuille",
      nbofrequest: "Nombre de feuilles demandées",
      nbelementspercopy: "Nombre d'éléments par PDF",
      nbelements: "Nombre d'éléments pour le calcul de coût",
      Roll: "Rouleau",
      items: "éléments",
      visualNumber: "Nombre de visuels",
      copy: "Copie",
      varnish2d3D: "Vernis effet 2D / 3D",
      foil2d3D: "Dorure effet 2D / 3D",
      opv2d3D: "OPV effet 2D / 3D",
      antifoil2d3d: "Antifoil Led Varnish",
      clearVarnish2d3d: "Clear Varnish",
      opv: "OPV (Vernis de protection)",
      opvType: "Type d'OPV",
      printingSpeed: "Vitesse de production de {{machineType}}",
      consumablescosts: "COÛTS DES CONSOMMABLES",
      inkcost: "Coût de l’Encre / Litre",
      varnishcost: "Coût du Vernis / Litre",
      antifoilcost: "Coût de l'Antifoil Led Varnish / Litre",
      clearVarnishCost: "Coût du Clear Varnish / Litre",
      foilcost: "Coût de la dorure / m²",
      substratecost: "Coût du Substrat / {{type}}",
      primercost: "Coût du Primer / Litre",
      sheet: "feuille",
      label: "étiquette",
      sqm: "m²",
      coveragerate: "TAUX DE COUVERTURE",
      sqmPrice: "Coût m²",
      clear_varnish_coverage: "Taux de couverture de Clear Varnish",
      antifoil_coverage: "Taux de couverture d'Antifoil",
      inkConsumption: "mL / {{sheets}} {{type}}",
      inkCons: "mL",
      maximizedWidth: "Maximized Foil with usage-roll(s) width (mm) :",
      maximizedLength: "Maximized Foil length usage / sheet",
      foilWaste: "Foil waste / Roll Up-&-Down",
      totalLengthWaste: "Total Foil length usage / sheet (incl. waste)",
      RoiLink: "Lier au ROI",
      start_no_pdf: "Démarrer une nouvelle estimation de coût sans fichier PDF",
      start_with_pdf:
        "Démarrer une nouvelle estimation de coût avec un fichier PDF",
      start: "Démarrer",
      foil_roll_size: "Largeur du rouleau de foil",
      foil_roll_size_info: "Valeur standard de largeur de rouleau MGI : 320",

      betweenHeight: "La valeur doit être comprise entre 50 et 980",
      betweenWidth: "La valeur doit être comprise entre 100 et 420",

      cyan: "Cyan",
      magenta: "Magenta",
      yellow: "Jaune",
      black: "Noir",
      Foil: "Dorure",
      foilVarnish: "Vernis {{foil}}",
      foilFilm: "Film {{foil}}",
      compactFoilFilm: "Film dorure",
      Varnish: "Vernis",
      spotUv: "Vernis sélectif",
      ANTIFOIL: "Antifoil Led Varnish",
      Antifoil: "Antifoil Led Varnish",

      activated_modules: "Modules activés",

      clearVarnish: "Clear Varnish",
      clearVarnishFoil: "Clear Varnish (Foil)",
      trueFoil: "Clear Varnish (Foil)",
      Substrate: "Substrat",
      CMYK: "CMJN",
      front: "Recto",
      back: "Verso",
      cout: "Coût",
      Visuel: "Visuel",
      element: "élement",
      Feuille: "Feuille",
      Importpdffile: "Importer un fichier PDF",
      no_pdf_file: "Estimation sans fichier PDF",
      ordraganddrop: "Ou glissez votre fichier ici",

      chooseEquipment: "Choisissez votre équipement",
      noEquipment: "Vous n'avez pas d'équipement",

      side: "Face",
      surface: "Surfaces (m²)",
      surfaceTotal: "Surface Totale (m²)",

      Vernis: "Vernis",
      Dorure: "Dorure",

      none: "Aucune",
      detectedElements: "Elements détectés",
      selectedElements:
        "Les éléments non cochés ne seront pas pris en compte dans le calcul total de la couleur",
      selectFoilLayer: "Sélectionner la couche de foil",
      selectAntifoilLayer:
        "Sélectionner la couche PDF utilisée pour l'Antifoil Led Varnish",
      selectVarnishLayer:
        "Sélectionner la couche PDF utilisée pour le Clear Varnish",
      foilLayerText:
        "Cette couche sera utilisée pour calculer la zone sur laquelle le foil sera appliqué",
      apply: "Appliquer",
      cancel: "Annuler",
      select_at_least: "Associer un calque pour continuer",

      add_estimate: "Nouvelle estimation",
      download: "Télécharger",
      save: "Enregistrer",

      item_detail: "Élément = étiquette, book, ...",

      //Actions
      delete: "Supprimer",
      edit: "Modifier",
      archive: "Télécharger",
      open_project: "Ouvrir le projet",

      addestimation: "Nouvelle Estimation",

      toasterrorenre: "Une erreur est survenue pendand l'enregistrement",
      toastenregistrer: "Votre estimation à bien été enregistrée",
      toasterror: "Une erreur est survenue",
      toastajour: "Votre Estimation à bien été mise à jour",

      Name: "Nom",
      User: "Utilisateur",
      Created: "Création",
      Modified: "Modification",
      VisualNumber: "Nombre de visuels",
      Total: "Total",

      ALPHAJET: "ALPHAJET",
      COMPACT: "JV3D WEB 400",

      select_modules:
        "Quels sont les modules que vous souhaitez activer pour cette production ?",
      hotfoiltext: "Quelle est la couche PDF utilisée pour la Dorure à Chaud ?",
      antifoiltext:
        "Quelle est la couche PDF utilisée pour le Vernis Anti Foil ?",
      clearVarnishtext:
        "Quelle est la couche PDF utilisée pour le Clear Varnish ?",
      clearVarnishHotFoiltext:
        "Quelle est la couche PDF utilisée pour le Clear Varnish et la Dorure à Chaud ?",
    },
  },

  ja: {
    translation: {},
  },
};
