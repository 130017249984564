import useSWR from "swr";
import { useBackend } from "../backend/BackendProvider";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

export function useCalculator(id: number) {
  const backend = useBackend();
  const key = ["roi"];
  return useSWR(key, async (_) => {
    const test: any = await backend
      .getCalculator(id)
      .then((e) => e)
      .catch(() => "Error");

    return test.error ? "Error" : test;
  });
}

export function useCalculatorList(machineType: MachineModelType) {
  const backend = useBackend();
  const key = ["calcualtorList"];
  return useSWR(key, async (_) => {
    return backend.getCalculatorList(machineType);
  });
}
