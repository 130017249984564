import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/choosemodal.module.scss";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";
import { useTranslation } from "react-i18next";
import { CompactAssociation } from "../calculatorTypes";

const ChoosemodalAlphajet = ({
  layers,
  setPdfId,
  setShowModal,
  machineType,
  setCompactVarnishAssociation,
  handleCancelUpload,
}: {
  layers: { document: string; preflight: string[] };
  setPdfId: React.Dispatch<string | undefined>;
  setShowModal: React.Dispatch<boolean>;
  machineType: string | undefined;
  setCompactVarnishAssociation: React.Dispatch<
    React.SetStateAction<CompactAssociation | undefined>
  >;
  handleCancelUpload: () => void;
}) => {
  const pdfToolBox = usePdfToolboxClient();

  const [localSelectedLayers, setLocalSelectedLayers] = useState<string[]>([]);

  const handleSelectLayers = useCallback(
    (l: string) => {
      const array = localSelectedLayers ?? [];
      if (localSelectedLayers?.includes(l)) {
        const index = localSelectedLayers.findIndex((v) => v === l);
        array?.splice(index, 1);
        setLocalSelectedLayers([...array]);
      } else {
        array.push(l);
        setLocalSelectedLayers([...array]);
      }
    },
    [localSelectedLayers]
  );

  const [selectedFoilLayer, setSelectedFoilLayer] = useState<string[]>([]);

  useEffect(() => {
    const cmykArray: string[] = [];
    layers.preflight.forEach((v) => {
      if (isCMYK(v)) cmykArray.push(v);
      setLocalSelectedLayers(cmykArray);
    });
  }, [layers.preflight, machineType]);

  const { t } = useTranslation();

  const handleApplyLayers = useCallback(async () => {
    try {
      await pdfToolBox
        .asyncProcess(
          layers.document,
          localSelectedLayers,
          selectedFoilLayer,
          undefined
        )
        .then((p) => {
          setPdfId(p.id);
          setCompactVarnishAssociation({
            varnish: undefined,
            antifoil: undefined,
          });
        });
    } finally {
      setShowModal(false);
    }
  }, [
    layers.document,
    localSelectedLayers,
    pdfToolBox,
    selectedFoilLayer,
    setCompactVarnishAssociation,
    setPdfId,
    setShowModal,
  ]);

  return (
    <>
      {/* class displaynone pour cacher la modal */}
      <div className={classNames(styles.choosemodal)}>
        <div className={classNames(styles.closezone)}></div>
        <div className={classNames(styles.choosezone)}>
          <div className={classNames(styles.choosemodalcontent)}>
            {machineType === "ALPHAJET" && (
              <>
                {" "}
                <div className={classNames(styles.choosetitle)}>
                  <p>{t("detectedElements")}</p>
                </div>
                <div className={classNames(styles.chooseinput)}>
                  {layers.preflight.map((l) => (
                    <div className={classNames(styles.tdinput)}>
                      <div className={classNames(styles.input)}>
                        <input
                          type="checkbox"
                          checked={localSelectedLayers.includes(l)}
                          disabled={isCMYK(l)}
                          name="choose"
                          id="chooseid"
                          onClick={() => handleSelectLayers(l)}
                        />
                      </div>
                      <p>{l}</p>
                    </div>
                  ))}
                  <p className={classNames(styles.texte)}>
                    {t("selectedElements")}
                  </p>
                </div>
              </>
            )}

            {
              <div className={classNames(styles.chooseinput)}>
                <p>{t("selectFoilLayer")}</p>
                <select
                  className={classNames(styles.select)}
                  value={"default"}
                  onChange={(l) =>
                    setSelectedFoilLayer([...selectedFoilLayer, l.target.value])
                  }
                >
                  <option value={"default"} disabled>
                    {t("selectFoilLayer")}
                  </option>
                  {layers.preflight
                    .filter((l) => !selectedFoilLayer.includes(l))
                    .map((l) => (
                      <option value={l}>{l}</option>
                    ))}
                </select>
                <div className={classNames(styles.foilLayerzone)}>
                  {selectedFoilLayer.map((layer) => (
                    <div className={classNames(styles.foilLayer)}>
                      <p> {layer}</p>
                      <span>
                        <svg
                          onClick={() =>
                            setSelectedFoilLayer(
                              selectedFoilLayer.filter((s) => s !== layer)
                            )
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.942"
                          height="16.942"
                          viewBox="0 0 16.942 16.942"
                        >
                          <path
                            id="Tracé_61"
                            data-name="Tracé 61"
                            d="M836.942,267.077l-1.706-1.706-6.765,6.765-6.765-6.765L820,267.077l6.765,6.765L820,280.606l1.706,1.706,6.765-6.765,6.765,6.765,1.706-1.706-6.765-6.765Z"
                            transform="translate(-820 -265.371)"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>

                <p className={classNames(styles.texte)}>{t("foilLayerText")}</p>
              </div>
            }
          </div>
          <div className={classNames(styles.validate)}>
            <div
              className={classNames(styles.button)}
              onClick={handleCancelUpload}
            >
              <p>{t("cancel")}</p>
            </div>
            <div
              className={classNames(styles.button)}
              onClick={() => handleApplyLayers()}
            >
              <p>{t("apply")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChoosemodalAlphajet;

function isCMYK(layer: string) {
  return (
    layer.toUpperCase().includes("CYAN") ||
    layer.toUpperCase().includes("MAGENTA") ||
    layer.toUpperCase().includes("YELLOW") ||
    layer.toUpperCase().includes("BLACK") ||
    layer.toUpperCase().includes("JAUNE") ||
    layer.toUpperCase().includes("NOIR")
  );
}
