import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { ThemeType, TopBar, useAuth } from "@mgi-labs/mgi-id";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CalculatorListFetch from "./components/calculatorListFetch";
import logo from "./img/logomgi.png";
import classNames from "classnames";
import styles from "./components/style/BigCostCalculator.module.scss";
import CalculatorFetch from "./components/calculatorFetch";
import i18n from "./Internationalization/i18n";
import Menu from "./components/menu";
import { useMachineBackend } from "./backend/MachineBackendProvider";
import ChooseEquipment from "./components/ChooseEquipment";
import {
  MachineModelType,
  mapMachineToImage,
} from "@mgi-labs/lib-liste-machine";
import { CALCULATOR_EQUIPMENTS_TYPES } from "./components/Domain/calculatorEquipmentTypes";

function Home() {
  const { initialized, auth } = useAuth();

  const changeLang = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, []);
  const [theme] = useState<ThemeType>("light");
  const [machineType, setMachineType] = useState<
    MachineModelType | undefined
  >();

  const backend = useMachineBackend();

  const [machineTypes, setMachineTypes] = useState<
    MachineModelType[] | undefined
  >();

  useEffect(() => {
    async function fetchMachines() {
      const machineList = await backend.getMachines();
      const typeList = machineList.map((m) => m.type);
      setMachineTypes(
        typeList
          .filter((c, index) => {
            return typeList.indexOf(c) === index;
          })
          .filter((e) => CALCULATOR_EQUIPMENTS_TYPES.includes(e))
      );
    }
    fetchMachines();
  }, [backend]);

  useEffect(() => {
    if (machineTypes?.length === 1) {
      setMachineType(machineTypes[0]);
    }
  }, [backend, machineTypes]);

  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <BrowserRouter>
      <Menu
        machineType={machineType}
        machineTypes={machineTypes}
        setMachineType={setMachineType}
      />
      <div className={classNames(styles.contentzone)}>
        {machineType && (
          <div className={classNames(styles.titlemachine)}>
            <img
              className={classNames(styles.logoMachine)}
              src={mapMachineToImage(machineType)}
              alt={"m"}
            />
          </div>
        )}
        <div className={classNames(styles.navbarzone)}>
          <TopBar
            logo={logo}
            pageName="CostCalculator"
            currentLang={i18n.language}
            changeLang={changeLang}
            theme={theme}
            backendUrl={process.env["REACT_APP_BACKEND_CONNECT_URL"]}
            connectUrl={process.env["REACT_APP_CONNECT_URL"]}
          />
        </div>
        <Routes>
          {machineTypes && (
            <Route
              path="/"
              element={
                <ChooseEquipment
                  setMachineType={setMachineType}
                  machineTypes={machineTypes}
                />
              }
            />
          )}
          <Route
            path="/:machineType/list"
            element={<CalculatorListFetch setMachineType={setMachineType} />}
          />
          <Route
            path="/:machineType/new/:id?"
            element={<CalculatorFetch setMachineType={setMachineType} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Home;
