import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import importimg from "../img/import.svg";
import Result from "./Result";
import Loading from "./Loading";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";
import { PdfPreflight, PdfPreflightResponse } from "../pdfPreflight.interface";
import { usePdf } from "../hooks/usePdf";
import ChoosemodalAlphajet from "./ChoosemodalAlphajet";
import CostCalculator from "./CostCalculator";
import { formatNumber } from "./calculator/calculator";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import {
  Calculator,
  CalculatorConfig,
  CompactAssociation,
  CostsType,
  CoverageType,
  Devise,
  mapConfigToCase,
  OpvType,
} from "../calculatorTypes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";
import { ConsoPerFace } from "./calculator/roiResult";
import { useUser } from "@mgi-labs/mgi-id";
import { testPdf } from "./testPdf";
import { OptimizedFoilUsagePerSheet } from "./calculator/staticInput";
import { getAvailableApps } from "../types/AvailableApps";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";
import AlphajetCoverage from "./AlphajetCoverageRate";
import CompactCoverage from "./CompactCoverage";
import {
  noPdfPreflightDefaultAlphajet,
  noPdfPreflightDefaultCompact,
} from "./Domain/calculatorEquipmentTypes";
import ChoosemodalCompact from "./ChoosemodalCompact";
import AlphajetSettings from "./AlphajetSettings";
import CompactSettings from "./CompactSettings";
import AlphajetCosts from "./AlphajetCosts";
import CompactCosts from "./CompactCosts";
import CompactModules from "./CompactModules";
import ChoosemodalCompactNoPdf from "./ChoosemodalCompactNoPdf";

const Coverage = ({
  //setAllInputsId,
  setVarnishA,
  varnishA,
  setAntifoil,
  antifoil,
  setFoilA,
  foilA,
  setTrueFoil,
  trueFoil,
  setOpvA,
  opvA,
  setVarnishB,
  varnishB,
  setFoilB,
  foilB,
  setOpvB,
  opvB,
  setInkCost,
  inkCost,
  setFoilRollSize,
  foilRollSize,
  setFoilCost,
  foilCost,
  setVarnishCost,
  varnishCost,
  setAntifoilCost,
  antifoilCost,
  setPaperCost,
  paperCost,
  primerCost,
  setPrimerCost,
  devise,
  setDevise,
  imageHeight,
  setImageHeight,
  imageWidth,
  setImageWidth,
  setFinalHeight,
  finalHeight,
  setFinalWidth,
  finalWidth,
  setUpsPerSheet,
  upsPerSheet,
  setSheetNumber,
  sheetNumber,
  calculator,
  mutate,
  fileName,
  setFileName,
  firstSave,
  setFirstSave,
  costs,
  setCosts,
  coverage,
  setCoverage,
  conso,
  setConso,
  foilInfoA,
  foilInfoB,
  setFoilInfoA,
  setFoilInfoB,
  opvType,
  setOpvType,
  opvA2d3d,
  setOpvA2d3d,
  opvB2d3d,
  setOpvB2d3d,
  compactVarnishAssociation,
  setCompactVarnishAssociation,
  setLoaded,
  noPdfProject,
  setNoPdfProject,
  localPdfValues,
  setLocalPdfValues,
  setConfig,
  config,
}: {
  //setAllInputsId: React.Dispatch<number | undefined>;
  setVarnishA: React.Dispatch<number>;
  varnishA: number;
  setAntifoil: React.Dispatch<number>;
  antifoil: number;
  setFoilA: React.Dispatch<number>;
  foilA: number;
  setTrueFoil: React.Dispatch<number>;
  trueFoil: number;
  setOpvA: React.Dispatch<boolean>;
  opvA: boolean;
  setVarnishB: React.Dispatch<number>;
  varnishB: number;
  setFoilB: React.Dispatch<number>;
  foilB: number;
  setOpvB: React.Dispatch<boolean>;
  opvB: boolean;
  setInkCost: React.Dispatch<number>;
  inkCost: number;
  setFoilRollSize: React.Dispatch<number>;
  foilRollSize: number;
  setFoilCost: React.Dispatch<number>;
  foilCost: number;
  setVarnishCost: React.Dispatch<number>;
  varnishCost: number;
  setAntifoilCost: React.Dispatch<number>;
  antifoilCost: number;
  setPaperCost: React.Dispatch<number>;
  paperCost: number;
  primerCost: number;
  setPrimerCost: React.Dispatch<number>;
  devise: Devise;
  setDevise: React.Dispatch<Devise>;
  imageHeight: number;
  setImageHeight: React.Dispatch<number>;
  imageWidth: number;
  setImageWidth: React.Dispatch<number>;
  finalHeight: number;
  setFinalHeight: React.Dispatch<number>;
  finalWidth: number;
  setFinalWidth: React.Dispatch<number>;
  setUpsPerSheet: React.Dispatch<number>;
  upsPerSheet: number;
  setSheetNumber: React.Dispatch<number>;
  sheetNumber: number;
  calculator: Calculator | undefined;
  mutate: KeyedMutator<any>;
  fileName: string;
  setFileName: React.Dispatch<string>;
  firstSave: boolean;
  setFirstSave: React.Dispatch<boolean>;
  costs: CostsType;
  setCosts: React.Dispatch<CostsType>;
  coverage: CoverageType;
  setCoverage: React.Dispatch<CoverageType>;
  conso: ConsoPerFace;
  setConso: React.Dispatch<ConsoPerFace>;
  foilInfoA: OptimizedFoilUsagePerSheet;
  foilInfoB: OptimizedFoilUsagePerSheet;
  setFoilInfoA: React.Dispatch<OptimizedFoilUsagePerSheet>;
  setFoilInfoB: React.Dispatch<OptimizedFoilUsagePerSheet>;
  opvType: OpvType;
  setOpvType: React.Dispatch<OpvType>;
  setOpvA2d3d: React.Dispatch<number>;
  opvA2d3d: number;
  setOpvB2d3d: React.Dispatch<number>;
  opvB2d3d: number;
  compactVarnishAssociation: CompactAssociation | undefined;
  setCompactVarnishAssociation: React.Dispatch<
    React.SetStateAction<CompactAssociation | undefined>
  >;
  setLoaded: React.Dispatch<boolean>;
  noPdfProject: boolean;
  setNoPdfProject: React.Dispatch<boolean>;
  localPdfValues: { [value: string]: number } | undefined;
  setLocalPdfValues: React.Dispatch<{ [value: string]: number } | undefined>;
  setConfig: React.Dispatch<CalculatorConfig>;
  config: CalculatorConfig;
}) => {
  const [pdfId, setPdfId] = useState<string>();
  const [pdfLayers, setPdfLayers] = useState<{
    document: string;
    preflight: string[];
  }>();

  const [showModal, setShowModal] = useState<boolean>(true);

  const [pdfPreflight, setPdfPreflight] = useState<PdfPreflight>();

  const { data } = usePdf(pdfId);

  const pdfToolBox = usePdfToolboxClient();

  const backend = useBackend();
  const navigate = useNavigate();
  const location = useLocation();

  const [noPdfStart, setNoPdfStart] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [foilSurface, setFoilSurface] = useState<number>();

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  const [isFocused, setIsFocused] = useState<boolean>(true);
  const [error, setError] = useState<{ width: boolean; height: boolean }>({
    width: false,
    height: false,
  });

  useEffect(() => {
    data &&
      setPdfPreflight({
        name: fileName,
        step: data.step,
        pdfPreflightResponse: data?.preflight,
      });
  }, [data, fileName]);

  const newPdfPreflight: PdfPreflight | undefined = useMemo(() => {
    let preflight = pdfPreflight ?? calculator?.data.pdfPreflight;

    if (machineType !== "ALPHAJET" && preflight)
      preflight.pdfPreflightResponse = preflight.pdfPreflightResponse
        ? [preflight.pdfPreflightResponse[0]]
        : undefined;
    return preflight;
  }, [calculator?.data.pdfPreflight, machineType, pdfPreflight]);

  useEffect(() => {
    newPdfPreflight && setIsLoading(newPdfPreflight.step < 100);
  }, [newPdfPreflight, newPdfPreflight?.step]);

  useEffect(() => {
    if (newPdfPreflight?.step === 100) setPdfId("");
  }, [newPdfPreflight?.step]);

  const [noPdfPreflight, setNoPdfPreflight] = useState<PdfPreflight>(
    machineType === "ALPHAJET"
      ? noPdfPreflightDefaultAlphajet
      : noPdfPreflightDefaultCompact
  );

  const [currentPdfPreflight, setCurrentPdfPreflight] = useState<
    PdfPreflightResponse[] | undefined
  >();

  // keep for later
  // useEffect(() => {
  //   if (!currentPdfPreflight) {
  //     setCurrentPdfPreflight(
  //       newPdfPreflight?.pdfPreflightResponse
  //         ? [...newPdfPreflight?.pdfPreflightResponse]
  //         : undefined
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newPdfPreflight?.pdfPreflightResponse]);

  const currentPreflight = useMemo(() => {
    if (newPdfPreflight) {
      if (noPdfProject) {
        if (machineType === "ALPHAJET") {
          return {
            ...newPdfPreflight,
            pdfPreflightResponse: noPdfPreflight.pdfPreflightResponse,
          };
        } else {
          return {
            ...newPdfPreflight,
            pdfPreflightResponse: noPdfPreflight.pdfPreflightResponse
              ? [noPdfPreflight.pdfPreflightResponse[0]]
              : undefined,
          };
        }
      } else {
        return newPdfPreflight;
      }
    } else {
      if (machineType === "ALPHAJET") {
        return noPdfPreflight;
      } else {
        return {
          ...noPdfPreflight,
          pdfPreflightResponse: noPdfPreflight.pdfPreflightResponse
            ? [noPdfPreflight.pdfPreflightResponse[0]]
            : undefined,
        };
      }
    }
  }, [machineType, newPdfPreflight, noPdfPreflight, noPdfProject]);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const file = event.target.files![0];
      if (file) {
        setIsLoading(true);
        setPdfPreflight(undefined);
        await pdfToolBox.asyncUpload(file).then((p) => {
          const newLayers: string[] = [];
          p.preflight.forEach((l) => newLayers.push(l));
          setPdfLayers({ document: p.document, preflight: newLayers });
          setFileName(file.name);
        });
      }
    },
    [pdfToolBox, setFileName]
  );

  const hasVarnish = currentPreflight?.pdfPreflightResponse?.some((v) =>
    v.otherColor.some(
      (o) =>
        o.name.toUpperCase().includes("VARNISH") ||
        o.name.toUpperCase().includes("VERNIS")
    )
  );

  const hasAntifoil = currentPreflight?.pdfPreflightResponse?.some((v) =>
    v.otherColor.some((o) => o.name === compactVarnishAssociation?.antifoil)
  );

  const foilList = useMemo(() => {
    const preflightFoils = currentPreflight?.pdfPreflightResponse?.flatMap(
      (p) => p.foils?.map((c) => c.name)
    );
    if (machineType === "ALPHAJET") {
      const otherColorFoils =
        currentPreflight?.pdfPreflightResponse?.flatMap((p) =>
          p.otherColor
            .filter(
              (c) =>
                c.name.toUpperCase().includes("FOIL") ||
                preflightFoils?.includes(c.name.toUpperCase())
            )
            .map((e) => e.name)
        ) ?? [];
      return otherColorFoils;
    } else {
      const otherColorFoils =
        currentPreflight?.pdfPreflightResponse?.flatMap((p) =>
          p.otherColor
            ?.filter(
              (c) =>
                c.name ===
                compactVarnishAssociation?.[
                  mapConfigToCase(config) === "case2" ||
                  mapConfigToCase(config) === "case3"
                    ? "foil"
                    : "varnish"
                ]
            )
            ?.map((e) => e.name)
        ) ?? [];
      return otherColorFoils;
    }
  }, [
    compactVarnishAssociation,
    config,
    currentPreflight?.pdfPreflightResponse,
    machineType,
  ]);

  const hasFoil = foilList.length > 0;

  const hasDigitalHotFoil = useMemo(
    () => (hasFoil && (foilA > 0 || foilB > 0)) ?? false,
    [foilA, foilB, hasFoil]
  );
  const thickness = Math.max(foilA, foilB, varnishA, varnishB);

  const alphaJetSpeed = setPrintingSpeed(hasDigitalHotFoil, thickness);

  const handleDropFromTable = useCallback(async () => {
    if (location.state) {
      const file = location.state as File;
      setIsLoading(true);
      setPdfPreflight(undefined);
      await pdfToolBox.asyncUpload(file).then((p) => {
        const newLayers: string[] = [];
        p.preflight.forEach((l) => newLayers.push(l));
        setPdfLayers({ document: p.document, preflight: newLayers });
        setFileName(file.name);
      });
    }
  }, [location.state, pdfToolBox, setFileName]);

  useEffect(() => {
    if (location.state && !pdfLayers) {
      handleDropFromTable();
    }
  }, [handleDropFromTable, location.state, pdfLayers]);

  const dropHandler = useCallback(
    async (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files![0];
      if (file) {
        setIsLoading(true);
        setPdfPreflight(undefined);
        await pdfToolBox.asyncUpload(file).then((p) => {
          const newLayers: string[] = [];
          p.preflight.forEach((l) => newLayers.push(l));
          setPdfLayers({ document: p.document, preflight: newLayers });
          setFileName(file.name);
        });
      }
    },
    [pdfToolBox, setFileName]
  );

  const visualNumber = useMemo(
    () => Math.ceil(sheetNumber * upsPerSheet),
    [sheetNumber, upsPerSheet]
  );

  const handleApplyLayers = useCallback(async () => {
    const hasPantone = pdfLayers?.preflight.some((p) =>
      p.toUpperCase().includes("PANTONE")
    );

    const pantoneList: string[] = [];
    pdfLayers?.preflight.forEach((v) => {
      v.toUpperCase().includes("PANTONE");
      pantoneList.push(v);
    });

    if (pdfLayers) {
      if (hasPantone) {
        try {
          await pdfToolBox
            .asyncProcess(pdfLayers?.document, pantoneList, [], "")
            .then((p) => {
              setPdfId(p.id);
            });
        } finally {
          setShowModal(false);
        }
      } else {
        try {
          await pdfToolBox
            .asyncProcess(pdfLayers?.document, [], [], "")
            .then((p) => {
              setPdfId(p.id);
            });
        } finally {
          setShowModal(false);
        }
      }
    }
  }, [pdfLayers, pdfToolBox]);

  useEffect(() => {
    //handleApplyLayers();
  }, [handleApplyLayers, pdfLayers]);

  const handleCancelUpload = useCallback(() => {
    setIsLoading(false);
    setShowModal(true);
    setPdfLayers(undefined);
    location.state = undefined;
  }, [location]);

  const handleCancelUploadNoPdf = useCallback(() => {
    setIsLoading(false);
    setPdfLayers(undefined);
    setNoPdfStart(false);
    setNoPdfProject(false);
    location.state = undefined;
  }, [location, setNoPdfProject]);

  useEffect(() => {
    if (currentPreflight?.pdfPreflightResponse) {
      const height = Math.round(
        currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.height
      );
      const width = Math.round(
        currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.width
      );

      setFinalHeight(height);
      setFinalWidth(width);
      setFoilInfoA({
        ...foilInfoA,
        maximizedFoilWidthUsageRollWidth: width,
        maximizedFoilLengthUsagePerSheet: height,
        TotalFoilLengthUsagePerSheet:
          height + foilInfoA.FoilMasterPerRollUpDown,
      });
      setFoilInfoB({
        ...foilInfoB,
        maximizedFoilWidthUsageRollWidth: width,
        maximizedFoilLengthUsagePerSheet: height,
        TotalFoilLengthUsagePerSheet:
          height + foilInfoB.FoilMasterPerRollUpDown,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPreflight,
    setFinalHeight,
    setFinalWidth,
    setFoilInfoA,
    setFoilInfoB,
  ]);

  const doubleSided = currentPreflight?.pdfPreflightResponse
    ? currentPreflight?.pdfPreflightResponse?.length > 1 &&
      machineType === "ALPHAJET"
    : false;

  const hasCMYK = currentPreflight?.pdfPreflightResponse?.some(
    (v) =>
      v.CMYKCoverage.Cyan > 0 ||
      v.CMYKCoverage.Magenta > 0 ||
      v.CMYKCoverage.Yellow > 0 ||
      v.CMYKCoverage.Black > 0
  );

  const hasOpv = useMemo(() => opvA || opvB, [opvA, opvB]);

  const { t, i18n } = useTranslation();

  const id = useMemo(
    () => location.pathname.split("/")[location.pathname.split("/").length - 1],
    [location.pathname]
  );

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const profile = useUser();

  const userApps = getAvailableApps(profile?.availableApps ?? []);

  const hasRoiAccess = userApps.includes("roi");

  const opvPercent = currentPreflight?.pdfPreflightResponse
    ?.flat()
    .map((n) =>
      n?.otherColor.filter((m) => m.name.toUpperCase().includes("OPV"))
    )
    .flat();

  const sqmPrice = useMemo(() => {
    const cost =
      costs.page.totalCostofJob -
      costs.page.costOfMaterial -
      costs.page.foilCosts.reduce((a, b) => a + b.total, 0);
    const height = Math.round(
      noPdfProject
        ? imageHeight
        : currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.height ??
            calculator?.data.visuSize.height ??
            1020
    );
    const width = Math.round(
      noPdfProject
        ? imageWidth
        : currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.width ??
            calculator?.data.visuSize.width ??
            720
    );
    return (
      cost / ((height * width) / 1000000) +
      paperCost +
      (hasFoil && (!noPdfProject || (noPdfProject && localPdfValues?.foilFront))
        ? foilCost
        : 0)
    );
  }, [
    calculator?.data.visuSize.height,
    calculator?.data.visuSize.width,
    costs.page.costOfMaterial,
    costs.page.foilCosts,
    costs.page.totalCostofJob,
    currentPreflight?.pdfPreflightResponse,
    foilCost,
    hasFoil,
    imageHeight,
    imageWidth,
    localPdfValues?.foilFront,
    noPdfProject,
    paperCost,
  ]);

  const handleRoiLink = useCallback(async () => {
    const calculatorData = {
      config: { printer1: false, printer2: false, ifoil: false },
      visuSize: {
        width: Math.round(
          currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.width ?? 720
        ),
        height: Math.round(
          currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.height ?? 1020
        ),
      },
      output: {
        size: {
          height: finalHeight,
          width: finalWidth,
        },
        upsPerSheet: upsPerSheet,
        copyNumber: sheetNumber,
      },
      infos: {
        front: {
          opv: opvA,
          opv2d3d: opvA2d3d,
          varnish: varnishA,
          antifoil: antifoil,
          foil: foilA,
          optimizedFoilUsagePerSheet: foilInfoA,
        },
        back: {
          opv: opvB,
          opv2d3d: opvB2d3d,
          varnish: varnishB,
          foil: foilB,
          optimizedFoilUsagePerSheet: foilInfoB,
        },
      },
      costs: {
        devise: devise,
        cmykCost: inkCost,
        varnishCost: varnishCost,
        antifoilCost: antifoilCost,
        foilCost: foilCost,
        substratCost: paperCost,
        foilRollSize,
        sqmPrice,
      },
      pdfPreflight: currentPreflight,
      doubleSided: doubleSided,
      name: fileName,
      allCosts: costs,
      coverage: coverage,
      foilSurface: foilSurface ?? 0,
      dates: { created: calculator?.created, modified: calculator?.modified },
    };
    let roi;
    try {
      roi = await backend.linkToRoi(
        calculatorData,
        fileName,
        opvType,
        opvPercent
      );
    } finally {
      if (roi)
        window
          .open(
            (process.env.REACT_APP_ROI_URL ??
              "https://roi-alphajet.mgi-labs.com/customer/") + roi.id,
            "_blank"
          )
          ?.focus();
    }
  }, [
    sqmPrice,
    currentPreflight,
    finalHeight,
    finalWidth,
    upsPerSheet,
    sheetNumber,
    opvA,
    opvA2d3d,
    varnishA,
    antifoil,
    foilA,
    foilInfoA,
    opvB,
    opvB2d3d,
    varnishB,
    foilB,
    foilInfoB,
    devise,
    inkCost,
    varnishCost,
    antifoilCost,
    foilCost,
    paperCost,
    doubleSided,
    fileName,
    costs,
    coverage,
    foilSurface,
    calculator?.created,
    calculator?.modified,
    backend,
    opvType,
    opvPercent,
    foilRollSize,
  ]);

  const handleDownload = useCallback(async () => {
    if (!isDownloading) {
      setIsDownloading(true);
      const calculatorData = {
        config: config,
        visuSize: {
          width: Math.round(
            currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.width ?? 720
          ),
          height: Math.round(
            currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.height ?? 1020
          ),
        },
        output: {
          size: {
            height: finalHeight,
            width: finalWidth,
          },
          upsPerSheet: upsPerSheet,
          copyNumber: sheetNumber,
        },
        infos: {
          front: {
            opv: opvA,
            varnish: varnishA,
            antifoil: antifoil,
            foil: foilA,
            trueFoil: trueFoil,
          },
          back: {
            opv: opvB,
            varnish: varnishB,
            foil: foilB,
          },
        },
        costs: {
          devise: devise,
          cmykCost: inkCost,
          varnishCost: varnishCost,
          antifoilCost: antifoilCost,
          foilCost: foilCost,
          substratCost: paperCost,
          foilRollSize,
          sqmPrice: sqmPrice,
        },
        pdfPreflight: currentPreflight,
        doubleSided: doubleSided,
        name: fileName,
        allCosts: costs,
        coverage: coverage,

        dates: { created: calculator?.created, modified: calculator?.modified },
      };
      try {
        await testPdf(
          i18n.language,
          profile,
          calculatorData,
          machineType ?? "ALPHAJET"
        );
      } finally {
        setIsDownloading(false);
      }
    } else {
      alert("Error when trying to create pdf, please restart process");
    }
  }, [
    isDownloading,
    config,
    currentPreflight,
    finalHeight,
    finalWidth,
    upsPerSheet,
    sheetNumber,
    opvA,
    varnishA,
    antifoil,
    foilA,
    opvB,
    varnishB,
    foilB,
    devise,
    inkCost,
    varnishCost,
    antifoilCost,
    foilCost,
    paperCost,
    foilRollSize,
    sqmPrice,
    doubleSided,
    fileName,
    costs,
    coverage,
    calculator?.created,
    calculator?.modified,
    i18n.language,
    profile,
    machineType,
    trueFoil,
  ]);

  const handleSaveCalucator = useCallback(async () => {
    const calculatorData = {
      config: config ?? { printer1: false, printer2: false, ifoil: false },
      visuSize: {
        width: noPdfProject
          ? imageWidth
          : Math.round(
              currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.width ?? 720
            ),
        height: noPdfProject
          ? imageHeight
          : Math.round(
              currentPreflight?.pdfPreflightResponse?.[0].PdfSize?.height ??
                1020
            ),
      },
      output: {
        size: {
          height: finalHeight,
          width: finalWidth,
        },
        upsPerSheet: upsPerSheet,
        copyNumber: sheetNumber,
      },
      infos: {
        front: {
          opv: opvA,
          varnish: varnishA,
          antifoil: antifoil,
          foil: foilA,
          opv2d3d: opvA2d3d,
          optimizedFoilUsagePerSheet: foilInfoA,
          trueFoil: trueFoil,
        },
        back: {
          opv: opvB,
          varnish: varnishB,
          foil: foilB,
          opv2d3d: opvB2d3d,
          optimizedFoilUsagePerSheet: foilInfoB,
        },
      },
      costs: {
        devise: devise,
        cmykCost: inkCost,
        varnishCost: varnishCost,
        antifoilCost: antifoilCost,
        foilCost: foilCost,
        substratCost: paperCost,
        primerCost: primerCost,
        foilRollSize: foilRollSize,
        sqmPrice: sqmPrice,
      },
      pdfPreflight: currentPreflight,
      doubleSided: doubleSided,
      allCosts: costs,
      coverage: coverage,
      opvType: opvType,
      foilSurface: foilSurface ?? 0,
      association: compactVarnishAssociation,
      noPdfProject: noPdfProject,
      noPdfValues: localPdfValues,
    };

    if (isNaN(parseInt(id)) && !firstSave) {
      await backend
        .addCostCalculator(calculatorData, fileName, machineType ?? "ALPHAJET")
        .then((e) => navigate("/" + machineType + "/new/" + e.id))
        .catch(() => toast.error(t("toasterrorenre")))
        .finally(() => {
          toast.success(t("toastenregistrer"));

          mutate();
        });
    } else {
      try {
        await backend.updateCostCalculator(
          parseInt(id),
          fileName,
          calculatorData
        );
      } catch {
        toast.error(t("toasterror"));
      } finally {
        toast.success(t("toastajour"));
        mutate();
      }
    }
  }, [
    config,
    noPdfProject,
    imageWidth,
    currentPreflight,
    imageHeight,
    finalHeight,
    finalWidth,
    upsPerSheet,
    sheetNumber,
    opvA,
    varnishA,
    antifoil,
    foilA,
    opvA2d3d,
    foilInfoA,
    opvB,
    varnishB,
    foilB,
    opvB2d3d,
    foilInfoB,
    devise,
    inkCost,
    varnishCost,
    antifoilCost,
    foilCost,
    paperCost,
    primerCost,
    foilRollSize,
    sqmPrice,
    doubleSided,
    costs,
    coverage,
    opvType,
    foilSurface,
    compactVarnishAssociation,
    localPdfValues,
    id,
    firstSave,
    backend,
    fileName,
    machineType,
    navigate,
    t,
    mutate,
    trueFoil,
  ]);

  useEffect(() => {
    if (
      !firstSave &&
      pdfPreflight?.step === 100 &&
      costs.visu.costOfMaterial &&
      fileName !== ""
    ) {
      handleSaveCalucator();
      setFirstSave(true);
    }
  }, [
    calculator,
    costs.visu.costOfMaterial,
    fileName,
    firstSave,
    handleSaveCalucator,
    pdfPreflight?.step,
    setFirstSave,
  ]);

  return (
    <>
      {/* <div className={classNames(styles.navbarzone2)}> */}

      {/* </div> */}

      <div className={classNames(styles.container)}>
        {(calculator || noPdfProject) && (
          <div className={classNames(styles.containerinfo)}>
            <div className={classNames(styles.result)}>
              <div className={classNames(styles.jobnamezone)}>
                {(currentPreflight?.pdfPreflightResponse || calculator) && (
                  <>
                    <div className={classNames(styles.jobname)}>
                      <p>
                        {t("nomdefichier")}
                        {i18n.language === "fr" ? " :" : ":"}
                      </p>
                      <input
                        type={"text"}
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        placeholder={t("nomdefichier")}
                      />
                      {/* <p title={fileName}>{fileName}</p> */}
                    </div>

                    {!noPdfProject && calculator && (
                      <div className={classNames(styles.infozone)}>
                        <div className={classNames(styles.oneinfo)}>
                          <p>
                            {t(
                              machineType === "ALPHAJET"
                                ? "taillevisu"
                                : "tailleimage"
                            )}
                            {i18n.language === "fr" ? " :" : ":"}
                          </p>
                          <p>
                            {Math.round(
                              currentPreflight?.pdfPreflightResponse?.[0]
                                .PdfSize?.width ??
                                calculator?.data.visuSize.width ??
                                720
                            )}
                            mm x{" "}
                            {Math.round(
                              currentPreflight?.pdfPreflightResponse?.[0]
                                .PdfSize?.height ??
                                calculator?.data.visuSize.height ??
                                1020
                            )}
                            mm
                          </p>
                        </div>
                      </div>
                    )}

                    {!noPdfProject && calculator && (
                      <div
                        className={classNames(
                          styles.thumbnailszone,
                          (currentPreflight?.pdfPreflightResponse?.[0] &&
                            machineType === "ALPHAJET") ||
                            calculator
                            ? styles.thumbnailszonecolor
                            : ""
                        )}
                      >
                        {doubleSided ? (
                          currentPreflight?.pdfPreflightResponse?.map(
                            (p, i) => (
                              <div className={classNames(styles.thumbnails)}>
                                <p>
                                  {currentPreflight?.pdfPreflightResponse
                                    ?.length === 1
                                    ? ""
                                    : i === 0
                                    ? t("front")
                                    : t("back")}{" "}
                                </p>
                                <img
                                  src={p.thumbnail}
                                  alt={`page ${p.page} thumbnail`}
                                />
                              </div>
                            )
                          )
                        ) : (
                          <div className={classNames(styles.thumbnails)}>
                            {machineType === "ALPHAJET" && <p>{t("front")}</p>}
                            <img
                              src={
                                currentPreflight?.pdfPreflightResponse?.[0]
                                  .thumbnail
                              }
                              alt={`page ${currentPreflight?.pdfPreflightResponse?.[0].page} thumbnail`}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {noPdfProject && (
                  <>
                    <div className={classNames(styles.onecontent)}>
                      <div className={classNames(styles.jobname)}>
                        <p>
                          {t("tailleimage")}
                          {i18n.language === "fr" ? " :" : ":"}
                        </p>
                      </div>
                      <div className={classNames(styles.infoFormat)}>
                        <p>
                          {t("Width")} {i18n.language === "fr" ? " :" : ":"}
                        </p>
                      </div>
                      <div className={classNames(styles.sides)}>
                        <div className={classNames(styles.sideA)}>
                          <input
                            className={classNames(
                              styles.paddinginput,
                              error.width && styles.inputerror
                            )}
                            type="number"
                            name="imageWidth"
                            id="imageWidth"
                            value={imageWidth ?? undefined}
                            onChange={(e) => {
                              setImageWidth(parseFloat(e.target.value));
                            }}
                          />
                          <span className={classNames(styles.unite)}>mm</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.onecontent,
                        styles.marginbottom
                      )}
                    >
                      <div className={classNames(styles.infoFormat)}>
                        <p>
                          {t("Height")}
                          {i18n.language === "fr" ? " :" : ":"}
                        </p>
                      </div>
                      <div className={classNames(styles.sides)}>
                        <div className={classNames(styles.sideA)}>
                          <input
                            className={classNames(
                              styles.paddinginput,
                              error.height && styles.inputerror
                            )}
                            type="number"
                            name="imageHeight"
                            id="imageHeight"
                            value={imageHeight ?? undefined}
                            onChange={(e) => {
                              setImageHeight(parseFloat(e.target.value));
                            }}
                          />
                          <span className={classNames(styles.unite)}>mm</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={classNames(styles.onecontent)}>
                  <div className={classNames(styles.jobname)}>
                    <p>
                      {t("formatsortie")}
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                  </div>
                  <div className={classNames(styles.infoFormat)}>
                    <p>
                      {t("Width")} ({t("Roll")})
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                    <div
                      className={classNames(styles.informationsbutton)}
                      data-title-info={t("betweenWidth")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <path
                          id="infos"
                          d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                          transform="translate(-4 -4)"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={classNames(styles.sides)}>
                    <div className={classNames(styles.sideA)}>
                      <input
                        className={classNames(
                          styles.paddinginput,
                          error.width && styles.inputerror
                        )}
                        type="number"
                        name="finalWidth"
                        id="finalWidth"
                        value={finalWidth ?? undefined}
                        onChange={(e) => {
                          setError({
                            ...error,
                            width:
                              parseFloat(e.target.value) < 100 ||
                              parseFloat(e.target.value) > 420,
                          });

                          setFinalWidth(parseFloat(e.target.value));
                        }}
                      />
                      <span className={classNames(styles.unite)}>mm</span>
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.onecontent)}>
                  <div className={classNames(styles.infoFormat)}>
                    <p>
                      {t("Height")}
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                    <div
                      className={classNames(styles.informationsbutton)}
                      data-title-info={t("betweenHeight")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <path
                          id="infos"
                          d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                          transform="translate(-4 -4)"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={classNames(styles.sides)}>
                    <div className={classNames(styles.sideA)}>
                      <input
                        className={classNames(
                          styles.paddinginput,
                          error.height && styles.inputerror
                        )}
                        type="number"
                        name="finalHeight"
                        id="finalHeight"
                        value={finalHeight ?? undefined}
                        onChange={(e) => {
                          setError({
                            ...error,
                            height:
                              parseFloat(e.target.value) < 50 ||
                              parseFloat(e.target.value) > 980,
                          });
                          setFinalHeight(parseFloat(e.target.value));
                        }}
                      />
                      <span className={classNames(styles.unite)}>mm</span>
                    </div>
                  </div>
                </div>

                {machineType === "ALPHAJET" && (
                  <>
                    <div className={classNames(styles.onecontent)}>
                      <div className={classNames(styles.infoFormat)}>
                        <p>
                          {t(
                            machineType === "ALPHAJET"
                              ? "visuelformat"
                              : "nbelementspercopy"
                          )}
                          {i18n.language === "fr" ? " :" : ":"}
                        </p>
                        <div
                          className={classNames(styles.informationsbutton)}
                          data-title-info={t("item_detail")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                          >
                            <path
                              id="infos"
                              d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={classNames(styles.sides, styles.littleside)}
                      >
                        <div className={classNames(styles.sideA)}>
                          <input
                            type="text"
                            name="upsPerSheet"
                            id="upsPerSheet"
                            defaultValue={upsPerSheet}
                            onChange={(e) => {
                              setUpsPerSheet(parseFloat(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classNames(styles.onecontent)}>
                      <p>
                        {t(
                          machineType === "ALPHAJET"
                            ? "nbofrequest"
                            : "nbelements"
                        )}
                        {i18n.language === "fr" ? " :" : ":"}
                      </p>
                      <div
                        className={classNames(styles.sides, styles.littleside)}
                      >
                        <div className={classNames(styles.sideA)}>
                          <input
                            type="text"
                            name="copyNumber"
                            id="copyNumber"
                            defaultValue={sheetNumber}
                            onChange={(e) => {
                              setSheetNumber(parseFloat(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {machineType === "ALPHAJET" && (
                  <div className={classNames(styles.onecontent)}>
                    <p>
                      {t("visualNumber")}
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                    <div
                      className={classNames(styles.sides, styles.littleside)}
                    >
                      <div className={classNames(styles.sideA)}>
                        <p>{visualNumber}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {noPdfProject && machineType === "COMPACT" && (
              <div
                className={classNames(
                  styles.onecontainer,
                  isFocused && styles.onecontainerfocused
                )}
              >
                <div className={classNames(styles.informations)}>
                  <div className={classNames(styles.titlezone)}>
                    <p className={classNames(styles.title)}>
                      {t("activated_modules")}
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                  </div>
                  <CompactModules config={config} setConfig={setConfig} />
                </div>
              </div>
            )}

            {noPdfProject && (
              <div
                className={classNames(
                  styles.onecontainer,
                  isFocused && styles.onecontainerfocused
                )}
              >
                <div className={classNames(styles.informations)}>
                  <div className={classNames(styles.titlezone)}>
                    <p className={classNames(styles.title)}>
                      {t("coveragerate")}
                      {i18n.language === "fr" ? " :" : ":"}
                    </p>
                  </div>
                  {machineType === "COMPACT" ? (
                    <CompactCoverage
                      coverage={coverage}
                      setCoverage={setCoverage}
                      doubleSided={doubleSided}
                      setNoPdfPreflight={setNoPdfPreflight}
                      currentPdfPreflight={currentPdfPreflight}
                      setCurrentPdfPreflight={setCurrentPdfPreflight}
                      noPdfPreflight={noPdfPreflight}
                      localPdfValues={localPdfValues}
                      setLocalPdfValues={setLocalPdfValues}
                      compactVarnishAssociation={compactVarnishAssociation}
                      isFocused={isFocused}
                      setIsFocused={setIsFocused}
                      finalHeight={finalHeight}
                      finalWidth={finalWidth}
                      config={config}
                    />
                  ) : (
                    <AlphajetCoverage
                      coverage={coverage}
                      setCoverage={setCoverage}
                      doubleSided={doubleSided}
                      noPdfPreflight={noPdfPreflight}
                      setNoPdfPreflight={setNoPdfPreflight}
                      localPdfValues={localPdfValues}
                      setLocalPdfValues={setLocalPdfValues}
                      isFocused={isFocused}
                      setIsFocused={setIsFocused}
                      finalHeight={finalHeight}
                      finalWidth={finalWidth}
                    />
                  )}
                </div>
              </div>
            )}

            {machineType === "ALPHAJET" ? (
              <AlphajetSettings
                currentPreflight={currentPreflight}
                machineType={machineType}
                doubleSided={doubleSided}
                alphaJetSpeed={alphaJetSpeed}
                opvType={opvType}
                setOpvType={setOpvType}
                hasVarnish={hasVarnish}
                hasFoil={hasFoil}
                varnishA={varnishA}
                setVarnishA={setVarnishA}
                varnishB={varnishB}
                setVarnishB={setVarnishB}
                foilA={foilA}
                setFoilA={setFoilA}
                foilB={foilB}
                setFoilB={setFoilB}
                opvA={opvA}
                setOpvA={setOpvA}
                opvB={opvB}
                setOpvB={setOpvB}
                opvA2d3d={opvA2d3d}
                setOpvA2d3d={setOpvA2d3d}
                opvB2d3d={opvB2d3d}
                setOpvB2d3d={setOpvB2d3d}
              />
            ) : (
              <CompactSettings
                doubleSided={doubleSided}
                hasAntifoil={hasAntifoil}
                hasFoil={hasFoil}
                antifoil={antifoil}
                setAntifoil={setAntifoil}
                foilA={foilA}
                setFoilA={setFoilA}
                foilB={foilB}
                setFoilB={setFoilB}
                config={config}
                trueFoil={trueFoil}
                setTrueFoil={setTrueFoil}
              />
            )}

            {machineType === "ALPHAJET" ? (
              <AlphajetCosts
                hasFoil={hasFoil}
                devise={devise}
                setDevise={setDevise}
                paperCost={paperCost}
                setPaperCost={setPaperCost}
                inkCost={inkCost}
                setInkCost={setInkCost}
                primerCost={primerCost}
                setPrimerCost={setPrimerCost}
                varnishCost={varnishCost}
                setVarnishCost={setVarnishCost}
                foilCost={foilCost}
                setFoilCost={setFoilCost}
              />
            ) : (
              <CompactCosts
                hasFoil={hasFoil}
                devise={devise}
                setDevise={setDevise}
                paperCost={paperCost}
                setPaperCost={setPaperCost}
                varnishCost={varnishCost}
                setVarnishCost={setVarnishCost}
                antifoilCost={antifoilCost}
                setAntifoilCost={setAntifoilCost}
                foilCost={foilCost}
                setFoilCost={setFoilCost}
                foilRollSize={foilRollSize}
                setFoilRollSize={setFoilRollSize}
                config={config}
              />
            )}

            {machineType === "COMPACT" && (
              <div className={classNames(styles.elementsTableContainer)}>
                <div className={classNames(styles.elementsTable)}>
                  <>
                    <div
                      className={classNames(styles.onecontent)}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <div className={classNames(styles.infoFormat)}>
                        <p>
                          {t("nbelementspercopy")}
                          {i18n.language === "fr" ? " :" : ":"}
                        </p>
                        <div
                          className={classNames(styles.informationsbutton)}
                          data-title-info={t("item_detail")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                          >
                            <path
                              id="infos"
                              d="M22.65,34h3V22h-3ZM24,18.3a1.643,1.643,0,0,0,1.175-.45,1.515,1.515,0,0,0,.475-1.15,1.681,1.681,0,0,0-.475-1.2,1.631,1.631,0,0,0-2.35,0,1.681,1.681,0,0,0-.475,1.2,1.515,1.515,0,0,0,.475,1.15A1.643,1.643,0,0,0,24,18.3ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm.05-3a16.3,16.3,0,0,0,12-4.975A16.483,16.483,0,0,0,41,23.95a16.342,16.342,0,0,0-4.95-12A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.441,16.441,0,0,0,24.05,41ZM24,24Z"
                              transform="translate(-4 -4)"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={classNames(styles.sides, styles.littleside)}
                      >
                        <div className={classNames(styles.sideA)}>
                          <input
                            type="text"
                            name="upsPerSheet"
                            id="upsPerSheet"
                            defaultValue={upsPerSheet}
                            onChange={(e) => {
                              setUpsPerSheet(parseFloat(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(styles.onecontent)}
                      style={{ width: "100%", display: "flex" }}
                    >
                      <p>
                        {t("nbelements")}
                        {i18n.language === "fr" ? " :" : ":"}
                      </p>
                      <div
                        className={classNames(styles.sides, styles.littleside)}
                      >
                        <div className={classNames(styles.sideA)}>
                          <input
                            type="text"
                            name="copyNumber"
                            id="copyNumber"
                            defaultValue={sheetNumber}
                            onChange={(e) => {
                              setSheetNumber(parseFloat(e.target.value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={classNames(
            styles.containerresult,
            !(calculator || noPdfProject) && styles.containerResultFull
          )}
        >
          {pdfLayers
            ? showModal &&
              (machineType === "ALPHAJET" ? (
                <ChoosemodalAlphajet
                  layers={pdfLayers}
                  setPdfId={setPdfId}
                  setShowModal={setShowModal}
                  handleCancelUpload={handleCancelUpload}
                  machineType={machineType}
                  setCompactVarnishAssociation={setCompactVarnishAssociation}
                />
              ) : (
                <ChoosemodalCompact
                  layers={pdfLayers}
                  setPdfId={setPdfId}
                  setShowModal={setShowModal}
                  handleCancelUpload={handleCancelUpload}
                  machineType={machineType}
                  setCompactVarnishAssociation={setCompactVarnishAssociation}
                  config={config}
                  setConfig={setConfig}
                />
              ))
            : noPdfStart &&
              machineType === "COMPACT" &&
              noPdfStart && (
                <ChoosemodalCompactNoPdf
                  setShowModal={setShowModal}
                  handleCancelUpload={handleCancelUploadNoPdf}
                  config={config}
                  setConfig={setConfig}
                  setNoPdfStart={setNoPdfStart}
                />
              )}
          <div className={classNames(styles.coverage)}>
            {newPdfPreflight?.step !== 100 && !noPdfProject && (
              <div style={{ height: "646px" }}>
                {!pdfLayers && (
                  <div className={classNames(styles.inputNoPdfZone)}>
                    {/* <p>Import PDF File :</p> */}
                    {!isLoading ? (
                      <div className={classNames(styles.inputzone)}>
                        <div className={classNames(styles.inputButtons)}>
                          <p>{t("start_no_pdf")}</p>
                          <label
                            htmlFor="file"
                            className={classNames(styles.labelButton)}
                            style={{ zIndex: 20 }}
                            onClick={(e) => {
                              e.preventDefault();
                              setLoaded(true);
                              setNoPdfProject(true);
                              setNoPdfStart(true);
                            }}
                          >
                            {t("start")}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <Loading step={newPdfPreflight?.step} />
                    )}
                  </div>
                )}

                {newPdfPreflight?.step !== 100 && !noPdfProject && (
                  <div className={classNames(styles.inputpdfzone)}>
                    {/* <p>Import PDF File :</p> */}
                    {!isLoading ? (
                      <div className={classNames(styles.inputzone)}>
                        <div className={classNames(styles.inputButtons)}>
                          <p style={{ opacity: 1, zIndex: 2 }}>
                            {t("start_with_pdf")}
                          </p>
                          {/* <label
                          htmlFor="file"
                          className={classNames(styles.labelButton)}
                          style={{ zIndex: 20 }}
                          onClick={(e) => {
                            e.preventDefault();
                            setLoaded(true);
                            setNoPdfProject(true);
                          }}
                        >
                          {t("no_pdf_file")}
                        </label> */}
                          <label
                            htmlFor="file"
                            className={classNames(styles.labelButton)}
                          >
                            {t("Importpdffile")}
                          </label>
                        </div>
                        <input
                          type="file"
                          id="file"
                          className={classNames(styles.inputimport)}
                          accept=".pdf"
                          onDragOver={dragOverHandler}
                          onDrop={dropHandler}
                          onChange={handleChange}
                        ></input>
                        <div
                          className={classNames(styles.inputimportzone)}
                        ></div>
                        <div className={classNames(styles.image)}>
                          {" "}
                          <img
                            className={classNames(styles.creation_importingimg)}
                            src={importimg}
                            alt="importimg"
                          ></img>
                        </div>
                        <p className={classNames(styles.textinput)}>
                          {t("ordraganddrop")}
                        </p>
                      </div>
                    ) : (
                      <Loading step={newPdfPreflight?.step} />
                    )}
                  </div>
                )}
              </div>
            )}

            {((newPdfPreflight && newPdfPreflight.step === 100) ||
              noPdfProject) && (
              <Result
                job={currentPreflight}
                inkCost={inkCost}
                conso={conso}
                setCoverage={setCoverage}
                opvA={opvA}
                opvB={opvB}
                hasOpv={hasOpv}
                opvType={opvType}
                sheetNumber={sheetNumber}
                foilList={foilList}
                association={
                  noPdfProject
                    ? { varnish: "foil", antifoil: "varnish", foil: "trueFoil" }
                    : compactVarnishAssociation
                }
                config={config}
              />
            )}
            {((newPdfPreflight && newPdfPreflight.step === 100) ||
              noPdfProject) && (
              <CostCalculator
                job={currentPreflight}
                varnishA={varnishA}
                antifoil={antifoil}
                foilA={foilA}
                trueFoil={trueFoil}
                opvA={opvA}
                varnishB={varnishB}
                foilB={foilB}
                opvB={opvB}
                inkCost={inkCost}
                varnishCost={varnishCost}
                antifoilCost={antifoilCost}
                foilCost={foilCost}
                paperCost={paperCost}
                primerCost={primerCost}
                finalHeight={error.height ? 0 : finalHeight}
                finalWidth={error.width ? 0 : finalWidth}
                upsPerSheet={upsPerSheet}
                sheetNumber={sheetNumber}
                setCosts={setCosts}
                costs={costs}
                hasFoil={hasFoil}
                hasVarnish={hasVarnish}
                hasAntifoil={hasAntifoil}
                hasCMYK={hasCMYK}
                hasOpv={hasOpv}
                devise={devise}
                setConso={setConso}
                foilInfoA={foilInfoA}
                foilInfoB={foilInfoB}
                opvType={opvType}
                opvA2d3d={opvA2d3d}
                opvB2d3d={opvB2d3d}
                foilList={foilList}
                doubleSided={doubleSided}
                setFoilSurface={setFoilSurface}
                compactVarnishAssociation={
                  noPdfProject
                    ? { varnish: "foil", antifoil: "varnish", foil: "trueFoil" }
                    : compactVarnishAssociation
                }
                foilRollSize={foilRollSize}
                visuSize={{
                  width: noPdfProject
                    ? imageWidth
                    : currentPreflight?.pdfPreflightResponse?.[0].PdfSize
                        ?.width ??
                      calculator?.data.visuSize.width ??
                      720,
                  length: noPdfProject
                    ? imageHeight
                    : currentPreflight?.pdfPreflightResponse?.[0].PdfSize
                        ?.height ??
                      calculator?.data.visuSize.height ??
                      1020,
                }}
                sqmPrice={sqmPrice}
                config={config}
              />
            )}
            {(newPdfPreflight?.step === 100 || noPdfProject) &&
              machineType === "COMPACT" && (
                <>
                  <div
                    className={classNames(styles.sqmPrice)}
                    style={{ display: "flex" }}
                  >
                    <div className={classNames(styles.sqmTableDiv)}>
                      <div style={{ width: "100%", display: "flex" }}>
                        <table className={classNames(styles.totalTable)}>
                          <tr>
                            <td
                              style={{ width: "25%", backgroundColor: "white" }}
                            >
                              {t("sqmPrice")}
                            </td>
                            <td
                              style={{ width: "75%", backgroundColor: "white" }}
                            >
                              {formatNumber(sqmPrice, 3)} {devise}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>

          {(newPdfPreflight?.step === 100 || noPdfProject) && (
            <>
              <div className={classNames(styles.resultzone)}>
                <div className={classNames(styles.results)}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <table className={classNames(styles.totalTable)}>
                      <tr>
                        <td>Total</td>
                        <td>
                          {formatNumber(costs.visu.totalCostofJob, 3)} {devise}
                        </td>
                        {machineType === "ALPHAJET" && (
                          <td>
                            {formatNumber(costs.page.totalCostofJob, 3)}{" "}
                            {devise}
                          </td>
                        )}
                        <td>
                          {formatNumber(costs.total.totalCostofJob, 3)} {devise}
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <p>Coût d’impression : {formatNumber(totalCost, 3)} {devise}</p>
            <p>Coût total : {formatNumber(totalCost * copyNumber, 3)} {devise}</p> */}
                </div>
                {/* <div className={classNames(styles.savebtn)}>
          <p>SAVE</p>
        </div> */}
              </div>
              <div className={classNames(styles.saveZone)}>
                <div></div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {machineType === "ALPHAJET" && hasRoiAccess && (
                    <button
                      onClick={handleRoiLink}
                      className={classNames(
                        machineType === "ALPHAJET" && hasRoiAccess
                          ? styles.saveButtons
                          : styles.saveButtons2
                      )}
                    >
                      {t("RoiLink")}
                    </button>
                  )}

                  <button
                    onClick={handleDownload}
                    className={classNames(
                      machineType === "ALPHAJET" && hasRoiAccess
                        ? styles.saveButtons
                        : styles.saveButtons2
                    )}
                  >
                    {t("download")}
                  </button>
                  <button
                    onClick={handleSaveCalucator}
                    className={classNames(
                      machineType === "ALPHAJET" && hasRoiAccess
                        ? styles.saveButtons
                        : styles.saveButtons2
                    )}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Coverage;
function dragOverHandler(ev: React.DragEvent<HTMLDivElement>) {
  ev.preventDefault();
}
// function userAppTheme<T>(userAppTheme: any): [any, any] {
//   throw new Error("Function not implemented.");
// }

function setPrintingSpeed(hasFoil: boolean, thickness: number) {
  if (thickness > 27) {
    return 1440;
  } else return 1800;
}
