import React from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";

const Loading = ({ step }: { step?: number }) => {
  return (
    <>
      <div className={classNames(styles.loadingzone)}>
        <div className={classNames(styles.inputimportzone)}></div>
        <div className={classNames(styles.loading)}>
          <div className={classNames(styles.ldsring)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>{step ? step + " %" : "Traitement..."}</p>
        </div>
      </div>
    </>
  );
};
export default Loading;
