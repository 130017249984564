import React from "react";
import "./App.css";
import PdfToolboxClientProvider from "./PdfProcessing/PdfToolboxClientProvider";
import { useAuth } from "@mgi-labs/mgi-id";
import { I18nextProvider } from "react-i18next";
import { BackendProvider } from "./backend/BackendProvider";
import Backend from "./backend/Backend";
import i18n from "./Internationalization/i18n";
import Alert from "./Alert";
import { MachineBackendProvider } from "./backend/MachineBackendProvider";
import MachineBackend from "./backend/machineBackend";
import Home from "./Home";

function App({
  backendUrl,
  machineBackendUrl,
}: {
  backendUrl: string;
  machineBackendUrl: string;
}) {
  const { initialized, auth } = useAuth();
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <BackendProvider value={new Backend(backendUrl, auth)}>
      <MachineBackendProvider
        value={new MachineBackend(machineBackendUrl, auth)}
      >
        <PdfToolboxClientProvider>
          <I18nextProvider i18n={i18n}>
            <Home />
            <Alert />
          </I18nextProvider>
        </PdfToolboxClientProvider>
      </MachineBackendProvider>
    </BackendProvider>
  );
}

export default App;
