import classNames from "classnames";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styles from "./style/Allprojects.module.scss";
import deleteproj from "../img/delete.svg";
import {
  Column,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import searchImg from "../img/searchimg.svg";
import { useBackend } from "../backend/BackendProvider";
import { KeyedMutator } from "swr";
import { Calculator } from "../calculatorTypes";
import HoverActions from "./hoverActions";
import { useUser } from "@mgi-labs/mgi-id";
import { testPdf } from "./testPdf";
import { useParams } from "react-router-dom";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const CalculatorTable = ({
  events,
  filterCustom,
  setFilterCustom,
  selectedRows,
  setSelectedRows,
  mutate,
}: {
  events: Calculator[];
  filterCustom: {
    search?: string;
  };
  setFilterCustom: React.Dispatch<
    React.SetStateAction<{
      search?: string;
    }>
  >;
  selectedRows: number[];
  setSelectedRows: React.Dispatch<number[]>;
  mutate: KeyedMutator<Calculator[]>;
}) => {
  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterCustom({ ...filterCustom, search: e.target.value });
    },
    [filterCustom, setFilterCustom]
  );
  const { t, i18n } = useTranslation();

  const { machineType } = useParams<{
    machineType: MachineModelType | undefined;
  }>();

  const columns: Column<Calculator>[] = useMemo(
    () => [
      {
        Header: t<string>("Name"),
        id: "name",
        accessor: (roi) => {
          return roi.name;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("User"),
        accessor: (roi) => {
          return roi.userName;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Created"),
        accessor: (roi) => {
          return roi.created;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Modified"),
        accessor: (roi) => {
          return roi.modified;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("VisualNumber"),
        accessor: (roi) => {
          return roi.data.output.copyNumber;
        },
        style: classNames(styles.borderright),
      },
      {
        Header: t<string>("Total"),
        accessor: (roi) => {
          return roi.data;
        },
        style: classNames(styles.borderright),
      },
    ],
    [t]
  );

  const backend = useBackend();

  const navigate = useNavigate();

  const handleCheckboxChange = useCallback(
    async (val: number) => {
      if (selectedRows.includes(val)) {
        const newselected = selectedRows.filter((s) => s !== val);
        setSelectedRows(newselected);
      } else {
        setSelectedRows([...selectedRows, val]);
      }
    },
    [selectedRows, setSelectedRows]
  );

  const handleDeleteProject = useCallback(async () => {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await backend.deleteCalculator(selectedRows);
      } finally {
        setSelectedRows([]);
        mutate();
      }
    }
  }, [backend, mutate, selectedRows, setSelectedRows, t]);

  const handleDeleteOneProject = useCallback(
    async (id: number) => {
      if (window.confirm(t("confirm_delete"))) {
        try {
          await backend.deleteCalculator([id]);
        } finally {
          mutate();
        }
      }
    },
    [backend, mutate, t]
  );

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const profile = useUser();

  const handleDownload = useCallback(
    async (calculator: Calculator) => {
      if (!isDownloading) {
        setIsDownloading(true);
        const calculatorData = {
          config: calculator.data.config,
          visuSize: {
            width: Math.round(
              calculator.data.pdfPreflight?.pdfPreflightResponse?.[0].PdfSize
                ?.width ?? 720
            ),
            height: Math.round(
              calculator.data.pdfPreflight?.pdfPreflightResponse?.[0].PdfSize
                ?.height ?? 1020
            ),
          },
          output: {
            size: {
              height: calculator.data.output.size.height,
              width: calculator.data.output.size.width,
            },
            upsPerSheet: calculator.data.output.upsPerSheet,
            copyNumber: calculator.data.output.copyNumber,
          },
          infos: {
            front: {
              opv: calculator.data.infos.front.opv,
              varnish: calculator.data.infos.front.varnish,
              foil: calculator.data.infos.front.foil,
              trueFoil: calculator.data.infos.front.trueFoil,
            },
            back: {
              opv: calculator.data.infos.front.opv,
              varnish: calculator.data.infos.front.varnish,
              foil: calculator.data.infos.front.foil,
            },
          },
          costs: {
            devise: calculator.data.costs.devise,
            cmykCost: calculator.data.costs.cmykCost,
            varnishCost: calculator.data.costs.varnishCost,
            foilCost: calculator.data.costs.foilCost,
            substratCost: calculator.data.costs.substratCost,
            sqmPrice: calculator.data.costs.sqmPrice,
            antifoilCost: calculator.data.costs.antifoilCost,
            foilRollSize: calculator.data.costs.foilRollSize,
          },
          pdfPreflight: calculator.data.pdfPreflight,
          doubleSided: calculator.data.doubleSided,
          name: calculator.name,
          allCosts: calculator.data.allCosts,
          coverage: calculator.data.coverage,
          foilRollSize: calculator.data.costs.foilRollSize,
          dates: {
            created: calculator?.created,
            modified: calculator?.modified,
          },
        };
        try {
          await testPdf(
            i18n.language,
            profile,
            calculatorData,
            machineType ?? "ALPHAJET"
          );
        } finally {
          setIsDownloading(false);
        }
      } else {
        alert("Error when trying to create pdf, please restart process");
      }
    },
    [i18n.language, isDownloading, machineType, profile]
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: events,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    setGlobalFilter(filterCustom.search);
  }, [filterCustom, setGlobalFilter]);

  const divRef = useRef<HTMLDivElement>(null);
  const [drag, setDrag] = useState<boolean>(false);
  const dragCounter = useRef<number>(0);

  const dropRef = useRef<HTMLDivElement>(null);

  const tableDivRef = useRef<HTMLTableSectionElement>(null);
  const [whiteDrop, setWhiteDrop] = useState<boolean>(false);

  const handleDrop = useCallback(
    async (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files![0];
      if (file) {
        navigate("/" + machineType + "/new", { state: file });
      }
    },
    [machineType, navigate]
  );

  return (
    <>
      <div
        className={classNames(styles.Allproject_project)}
        ref={divRef}
        onDrag={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDrag(true);
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dragCounter.current++;
          setDrag(true);
        }}
        onDragEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dragCounter.current--;
          if (dragCounter.current > 0) return;
          setDrag(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDrag(false);
          dragCounter.current = 0;
        }}
      >
        <div className={classNames(styles.inputDiv)}>
          <div className={classNames(styles.searchDiv)}>
            <div className={classNames(styles.filterModule_insert_logo)}>
              <img
                className={classNames(styles.marginRightImg)}
                src={searchImg}
                alt="search"
              />
              <div className={classNames(styles.separation_input)}></div>
            </div>
            <div className={classNames(styles.search)}>
              <input
                defaultValue={filterCustom.search}
                onChange={(e) => handleChangeSearch(e)}
                type="text"
              />
            </div>
          </div>
        </div>
        {/* <div className={classNames(styles.filter_container)}>
          <LogsFilter filter={filterCustom} setFilter={setFilterCustom} />
        </div> */}
        <div className={classNames(styles.table)}>
          <table
            className={classNames(styles.Allproject_projects_section)}
            {...getTableProps()}
          >
            <thead
              className={classNames(
                styles.Allproject_projects_section_sort,
                styles.col_12
              )}
            >
              <button
                className={styles.buttonDelete}
                onClick={handleDeleteProject}
                style={{
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
              >
                <img
                  src={deleteproj}
                  title="Delete"
                  alt="Supprimer le projet"
                />
              </button>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classNames(styles.Allproject_padding)}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      className={classNames(
                        styles.Allproject_projects_sort,
                        styles.col_2
                      )}
                      //{...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {/* <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={arrowtop} alt="arrow top" />
                          ) : (
                            <img src={arrowbottom} alt="arrow bottom" />
                          )
                        ) : (
                          ""
                        )}
                      </span> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className={classNames(
                styles.Allproject_projects_content,
                styles.col_12,
                styles.row
              )}
              onDrop={handleDrop}
              ref={tableDivRef}
              onDragOver={(e) => {
                e.preventDefault();
                drag && setWhiteDrop(true);
              }}
              onDragLeave={(e) => {
                tableDivRef.current?.contains(e.target as HTMLElement) &&
                  setWhiteDrop(false);
              }}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.original.id}>
                    <Link to={"/" + machineType + "/new/" + row.original.id}>
                      <tr className={classNames(styles.col_12)}>
                        <td
                          className={classNames(styles.col_2)}
                          style={{ justifyContent: "space-around" }}
                        >
                          <div
                            className={classNames(styles.onecell)}
                            style={{ alignItems: "center" }}
                          >
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <input
                              className={classNames(styles.bat_checkmark)}
                              type="checkbox"
                              id="color"
                              name="process"
                              value="color"
                              checked={selectedRows.includes(row.original.id)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() =>
                                handleCheckboxChange(row.original.id)
                              }
                            />
                            <div
                              className={classNames(
                                styles.client_name,
                                styles.firstclientname
                              )}
                            >
                              <p title={row.cells[0].value}>
                                {row.cells[0].value}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[1].value}</p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>
                                {new Date(
                                  row.cells[2].value
                                ).toLocaleDateString(undefined, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>
                                {new Date(
                                  row.cells[3].value
                                ).toLocaleDateString(undefined, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[4].value}</p>
                            </div>
                          </div>
                        </td>

                        <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>
                                {parseFloat(
                                  row.cells[5].value.allCosts.total
                                    .totalCostofJob
                                ).toFixed(3)}{" "}
                                {row.cells[5].value.costs.devise}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td className={classNames(styles.col_3)}>
                          <div className={classNames(styles.onecell)}>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[3].value}</p>
                            </div>
                          </div>
                        </td> */}
                        {/* <td className={classNames(styles.col_2)}>
                          <div className={classNames(styles.onecell)}>
                            <div
                              className={classNames(styles.borderright)}
                            ></div>
                            <div className={classNames(styles.client_name)}>
                              <p>{row.cells[4].value.toString()}</p>
                            </div>
                          </div>
                        </td> */}

                        <HoverActions
                          id={row.original.id}
                          handleDeleteOne={handleDeleteOneProject}
                          handleDownload={handleDownload}
                          calculator={row.original}
                          machineType={machineType}
                        />
                      </tr>
                    </Link>
                  </React.Fragment>
                );
              })}
              <tr
                className={classNames(
                  styles.Allproject_project,
                  styles.Allproject_project_add,
                  "col-12 row"
                )}
              >
                <td
                  className={classNames(
                    styles.Allproject_project_Addicon,
                    "col-12"
                  )}
                >
                  <div className={classNames(styles.Allproject_project_icon)}>
                    <Link to={`/${machineType}/new`}>
                      <div
                        className={classNames(
                          styles.Allproject_project_iconText
                        )}
                      >
                        <p>{t("addestimation")}</p>
                      </div>
                    </Link>
                  </div>
                </td>
              </tr>
              {
                <div
                  className={classNames(
                    styles.importhover,
                    whiteDrop && styles.importhoverwhite,

                    !drag ? styles.displaynone : ""
                  )}
                  ref={dropRef}
                >
                  <div className={classNames(styles.pointillet)}>
                    <div className={classNames(styles.icon)}></div>
                  </div>
                </div>
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export function eventsColors(value: string | undefined) {
  if (value === "ERROR") {
    return "#EFAA53";
  } else if (value === "WARNING") {
    return "#EFAA53";
  } else if (value === "EMERGENCY STOP") {
    return "#E8615B";
  } else if (value === "STOP") {
    return "#E8615B";
  } else {
    return "#7C8FF8";
  }
}
export default CalculatorTable;
