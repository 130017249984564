import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import { useTranslation } from "react-i18next";
import {
  CalculatorConfig,
  CompactAssociation,
  CoverageType,
  mapConfigToCase,
} from "../calculatorTypes";
import { PdfPreflight, PdfPreflightResponse } from "../pdfPreflight.interface";

const CompactCoverage = ({
  coverage,
  setCoverage,
  doubleSided,
  setNoPdfPreflight,
  noPdfPreflight,
  localPdfValues,
  setLocalPdfValues,
  currentPdfPreflight,
  setCurrentPdfPreflight,
  compactVarnishAssociation,
  isFocused,
  setIsFocused,
  finalHeight,
  finalWidth,
  config,
}: {
  coverage: CoverageType;
  setCoverage: React.Dispatch<CoverageType>;
  doubleSided: boolean;
  setNoPdfPreflight: React.Dispatch<PdfPreflight>;
  currentPdfPreflight: PdfPreflightResponse[] | undefined;
  setCurrentPdfPreflight: React.Dispatch<PdfPreflightResponse[] | undefined>;
  noPdfPreflight: PdfPreflight;
  localPdfValues: { [value: string]: number } | undefined;
  setLocalPdfValues: React.Dispatch<{ [value: string]: number } | undefined>;
  compactVarnishAssociation: CompactAssociation | undefined;
  isFocused: boolean;
  setIsFocused: React.Dispatch<boolean>;
  finalHeight: number;
  finalWidth: number;
  config: CalculatorConfig;
}) => {
  const { t } = useTranslation();

  const [localFoilFront, setLocalFoilFront] = useState<number>(
    localPdfValues?.foilFront ?? 0
  );
  const [localVarnishFront, setLocalVarnishFront] = useState<number>(
    localPdfValues?.varnishFront ?? 0
  );
  const [localTrueFoilFront, setLocalTrueFoilFront] = useState<number>(
    localPdfValues?.trueFoilFront ?? 0
  );

  useEffect(() => {
    const newPdfPreflightResponse = [
      {
        page: 1,
        CMYKCoverage: {
          Cyan: 0,
          Magenta: 0,
          Yellow: 0,
          Black: 0,
        },
        otherColor: [
          {
            name: "foil",
            percent: localFoilFront,
          },
          {
            name: "varnish",
            percent: localVarnishFront,
          },
          {
            name: "opv",
            percent: 0,
          },
          {
            name: "trueFoil",
            percent: localTrueFoilFront,
          },
        ],
        thumbnail: "",
        foils: [
          {
            name: "foil",
            surface: finalHeight * finalWidth,
            url: "",
            foils: [
              {
                cover: finalHeight * finalWidth,
                width: finalWidth,
                height: finalHeight,
              },
            ],
          },
          {
            name: "trueFoil",
            surface: finalHeight * finalWidth,
            url: "",
            foils: [
              {
                cover: finalHeight * finalWidth,
                width: finalWidth,
                height: finalHeight,
              },
            ],
          },
        ],
        PdfSize: {
          width: finalWidth,
          height: finalHeight,
        },
      },
      {
        page: 2,
        CMYKCoverage: {
          Cyan: 0,
          Magenta: 0,
          Yellow: 0,
          Black: 0,
        },
        otherColor: [
          {
            name: "foil",
            percent: 0,
          },
          {
            name: "varnish",
            percent: 0,
          },
          {
            name: "opv",
            percent: 0,
          },
        ],
        thumbnail: "",
        foils: [
          {
            name: "foil",
            surface: finalHeight * finalWidth,
            url: "",
            foils: [
              {
                cover: finalHeight * finalWidth,
                width: finalWidth,
                height: finalHeight,
              },
            ],
          },
        ],
        PdfSize: {
          width: finalWidth,
          height: finalHeight,
        },
      },
    ];
    setNoPdfPreflight({
      ...noPdfPreflight,
      pdfPreflightResponse: newPdfPreflightResponse,
    });

    const newCurrentPdfPreflightResponse: any = [];

    currentPdfPreflight?.forEach((c, i) => {
      const clearVarnishPercent = c.otherColor.find(
        (f) => f.name === compactVarnishAssociation?.varnish
      );
      const antifoilPercent = c.otherColor.find(
        (f) => f.name === compactVarnishAssociation?.antifoil
      );
      const foilPercent = c.otherColor.find(
        (f) => f.name === compactVarnishAssociation?.foil
      );

      const clearVarnish = {
        name: compactVarnishAssociation?.varnish,
        percent: localFoilFront ?? clearVarnishPercent?.percent,
      };
      const antifoil = {
        name: compactVarnishAssociation?.antifoil,
        percent: localVarnishFront ?? antifoilPercent?.percent,
      };

      const foil = {
        name: compactVarnishAssociation?.foil,
        percent: localTrueFoilFront ?? foilPercent,
      };

      if (i === 0) {
        newCurrentPdfPreflightResponse.push({
          ...c,
          otherColor: [antifoil, clearVarnish, foil],
        });
      }
    });
    setCurrentPdfPreflight(newCurrentPdfPreflightResponse);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localFoilFront,
    localVarnishFront,
    localTrueFoilFront,
    setLocalPdfValues,
    setNoPdfPreflight,
  ]);

  useEffect(() => {
    setLocalPdfValues({
      foilFront: localFoilFront,
      varnishFront: localVarnishFront,
      trueFoilFront: localTrueFoilFront,
    });

    if (localFoilFront || localVarnishFront || localTrueFoilFront) {
      setIsFocused(false);
    }
  }, [
    localFoilFront,
    localTrueFoilFront,
    localVarnishFront,
    setIsFocused,
    setLocalPdfValues,
  ]);

  const configCase = mapConfigToCase(config);

  return (
    <>
      {(configCase === "case1" ||
        configCase === "case2" ||
        configCase === "case4" ||
        configCase === "case5") && (
        <div className={classNames(styles.onecontent)}>
          <p>
            {t(configCase === "case4" ? "clearVarnishFoil" : "clearVarnish")}
            &nbsp;:
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localFoilFront}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalFoilFront(parseFloat(e.target.value));
                  } else {
                    setLocalFoilFront(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          </div>
        </div>
      )}
      {(configCase === "case2" || configCase === "case3") && (
        <div className={classNames(styles.onecontent)}>
          <p>{t("Clear Varnish (Foil)")}&nbsp;:</p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localTrueFoilFront}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalTrueFoilFront(parseFloat(e.target.value));
                  } else {
                    setLocalTrueFoilFront(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          </div>
        </div>
      )}
      {(configCase === "case4" ||
        configCase === "case5" ||
        configCase === "case6") && (
        <div className={classNames(styles.onecontent)}>
          <p>{t("Antifoil")}&nbsp;:</p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localVarnishFront}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalVarnishFront(parseFloat(e.target.value));
                  } else {
                    setLocalVarnishFront(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompactCoverage;
