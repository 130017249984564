import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import { useTranslation } from "react-i18next";
import { CoverageType } from "../calculatorTypes";
import { PdfPreflight } from "../pdfPreflight.interface";

const AlphajetCoverage = ({
  coverage,
  setCoverage,
  doubleSided,
  setNoPdfPreflight,
  noPdfPreflight,
  localPdfValues,
  setLocalPdfValues,
  isFocused,
  setIsFocused,
  finalHeight,
  finalWidth,
}: {
  coverage: CoverageType;
  setCoverage: React.Dispatch<CoverageType>;
  doubleSided: boolean;
  noPdfPreflight: PdfPreflight;
  setNoPdfPreflight: React.Dispatch<PdfPreflight>;
  localPdfValues: { [value: string]: number } | undefined;
  setLocalPdfValues: React.Dispatch<{ [value: string]: number } | undefined>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<boolean>;
  finalHeight: number;
  finalWidth: number;
}) => {
  const { t } = useTranslation();

  const [localCyanFront, setLocalCyanFront] = useState<number>(
    localPdfValues?.cyanFront ?? 0
  );
  const [localMagentaFront, setLocalMagentaFront] = useState<number>(
    localPdfValues?.magentaFront ?? 0
  );
  const [localYellowFront, setLocalYellowFront] = useState<number>(
    localPdfValues?.yellowFront ?? 0
  );
  const [localBlackFront, setLocalBlackFront] = useState<number>(
    localPdfValues?.blackFront ?? 0
  );

  const [localFoilFront, setLocalFoilFront] = useState<number>(
    localPdfValues?.foilFront ?? 0
  );
  const [localVarnishFront, setLocalVarnishFront] = useState<number>(
    localPdfValues?.varnishFront ?? 0
  );
  const [localOPVFront, setLocalOPVFront] = useState<number>(
    localPdfValues?.opvFront ?? 0
  );

  const [localCyanBack, setLocalCyanBack] = useState<number>(
    localPdfValues?.cyanBack ?? 0
  );
  const [localMagentaBack, setLocalMagentaBack] = useState<number>(
    localPdfValues?.magentaBack ?? 0
  );
  const [localYellowBack, setLocalYellowBack] = useState<number>(
    localPdfValues?.yellowBack ?? 0
  );
  const [localBlackBack, setLocalBlackBack] = useState<number>(
    localPdfValues?.blackBack ?? 0
  );

  const [localFoilBack, setLocalFoilBack] = useState<number>(
    localPdfValues?.foilBack ?? 0
  );
  const [localVarnishBack, setLocalVarnishBack] = useState<number>(
    localPdfValues?.varnishBack ?? 0
  );
  const [localOPVBack, setLocalOPVBack] = useState<number>(
    localPdfValues?.opvBack ?? 0
  );

  useEffect(() => {
    const newPdfPreflightResponse = [
      {
        page: 1,
        CMYKCoverage: {
          Cyan: localCyanFront,
          Magenta: localMagentaFront,
          Yellow: localYellowFront,
          Black: localBlackFront,
        },
        otherColor: [
          {
            name: "foil",
            percent: localFoilFront,
          },
          {
            name: "varnish",
            percent: localVarnishFront,
          },
          {
            name: "opv",
            percent: localOPVFront,
          },
        ],
        thumbnail: "",
        foils: [
          {
            name: "foil",
            surface: finalHeight * finalWidth,
            url: "",
            foils: [
              {
                cover: finalHeight * finalWidth,
                width: finalWidth,
                height: finalHeight,
              },
            ],
          },
        ],
        PdfSize: {
          width: finalWidth,
          height: finalHeight,
        },
      },
      {
        page: 2,
        CMYKCoverage: {
          Cyan: localCyanBack,
          Magenta: localMagentaBack,
          Yellow: localYellowBack,
          Black: localBlackBack,
        },
        otherColor: [
          {
            name: "foil",
            percent: localFoilBack,
          },
          {
            name: "varnish",
            percent: localVarnishBack,
          },
          {
            name: "opv",
            percent: localOPVBack,
          },
        ],
        thumbnail: "",
        foils: [
          {
            name: "foil",
            surface: finalHeight * finalWidth,
            url: "",
            foils: [
              {
                cover: finalHeight * finalWidth,
                width: finalWidth,
                height: finalHeight,
              },
            ],
          },
        ],
        PdfSize: {
          width: finalWidth,
          height: finalHeight,
        },
      },
    ];
    setNoPdfPreflight({
      ...noPdfPreflight,
      pdfPreflightResponse: newPdfPreflightResponse,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localBlackBack,
    localBlackFront,
    localCyanBack,
    localCyanFront,
    localFoilBack,
    localFoilFront,
    localMagentaBack,
    localMagentaFront,
    localOPVBack,
    localOPVFront,
    localVarnishBack,
    localVarnishFront,
    localYellowBack,
    localYellowFront,
    //noPdfPreflight,
    setNoPdfPreflight,
  ]);

  useEffect(() => {
    setLocalPdfValues({
      cyanFront: localCyanFront,
      magentaFront: localMagentaFront,
      yellowFront: localYellowFront,
      blackFront: localBlackFront,
      foilFront: localFoilFront,
      varnishFront: localVarnishFront,
      opvFront: localOPVFront,
      cyanBack: localCyanBack,
      magentaBack: localMagentaBack,
      yellowBack: localYellowBack,
      blackBack: localBlackBack,
      foilBack: localFoilBack,
      varnishBack: localVarnishBack,
      opvBack: localOPVBack,
    });

    if (
      localCyanFront ||
      localMagentaFront ||
      localYellowFront ||
      localBlackFront ||
      localFoilFront ||
      localVarnishFront ||
      localOPVFront ||
      localCyanBack ||
      localMagentaBack ||
      localYellowBack ||
      localBlackBack ||
      localFoilBack ||
      localVarnishBack ||
      localOPVBack
    ) {
      setIsFocused(false);
    }
  }, [
    localBlackBack,
    localBlackFront,
    localCyanBack,
    localCyanFront,
    localFoilBack,
    localFoilFront,
    localMagentaBack,
    localMagentaFront,
    localOPVBack,
    localOPVFront,
    localVarnishBack,
    localVarnishFront,
    localYellowBack,
    localYellowFront,
    setIsFocused,
    setLocalPdfValues,
  ]);

  return (
    <>
      <div className={classNames(styles.onecontent)}>
        <p>{t("cyan")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localCyanFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalCyanFront(parseFloat(e.target.value));
                } else {
                  setLocalCyanFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localCyanBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalCyanBack(parseFloat(e.target.value));
                  } else {
                    setLocalCyanBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>{t("magenta")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localMagentaFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalMagentaFront(parseFloat(e.target.value));
                } else {
                  setLocalMagentaFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localMagentaBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalMagentaBack(parseFloat(e.target.value));
                  } else {
                    setLocalMagentaBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>{t("yellow")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localYellowFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalYellowFront(parseFloat(e.target.value));
                } else {
                  setLocalYellowFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localYellowBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalYellowBack(parseFloat(e.target.value));
                  } else {
                    setLocalYellowBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>{t("black")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localBlackFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalBlackFront(parseFloat(e.target.value));
                } else {
                  setLocalBlackFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localBlackBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalBlackBack(parseFloat(e.target.value));
                  } else {
                    setLocalBlackBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>

      <div className={classNames(styles.onecontent)}>
        <p>{t("Foil")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localFoilFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalFoilFront(parseFloat(e.target.value));
                } else {
                  setLocalFoilFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localFoilBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalFoilBack(parseFloat(e.target.value));
                  } else {
                    setLocalFoilBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>{t("Varnish")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localVarnishFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalVarnishFront(parseFloat(e.target.value));
                } else {
                  setLocalVarnishFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localVarnishBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalVarnishBack(parseFloat(e.target.value));
                  } else {
                    setLocalVarnishBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>{t("opv")}&nbsp;:</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideA)}>
            <input
              className={classNames(styles.paddinginput, styles.inputPercent)}
              type="text"
              name="inkC"
              id="inkC"
              value={localOPVFront}
              onChange={(e) => {
                if (!isNaN(parseFloat(e.target.value))) {
                  setLocalOPVFront(parseFloat(e.target.value));
                } else {
                  setLocalOPVFront(0);
                }
              }}
            />
            <span className={classNames(styles.unite)}>%</span>
          </div>
          {doubleSided && (
            <div className={classNames(styles.sideB)}>
              <input
                className={classNames(styles.paddinginput, styles.inputPercent)}
                type="text"
                name="inkC"
                id="inkC"
                value={localOPVBack}
                onChange={(e) => {
                  if (!isNaN(parseFloat(e.target.value))) {
                    setLocalOPVBack(parseFloat(e.target.value));
                  } else {
                    setLocalOPVBack(0);
                  }
                }}
              />
              <span className={classNames(styles.unite)}>%</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AlphajetCoverage;
