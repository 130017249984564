import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "@mgi-labs/mgi-id";
import { injectStyle } from "react-toastify/dist/inject-style";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const backendUrl = process.env["REACT_APP_BACKEND_CALCULATOR_URL"];
const machineBackendUrl = process.env["REACT_APP_MACHINE_BACKEND_URL"];

injectStyle();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App
        backendUrl={backendUrl ?? ""}
        machineBackendUrl={machineBackendUrl ?? ""}
      />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
