import React from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Devise } from "../calculatorTypes";

const AlphajetCosts = ({
  hasFoil,
  devise,
  setDevise,
  paperCost,
  setPaperCost,
  inkCost,
  setInkCost,
  primerCost,
  setPrimerCost,
  varnishCost,
  setVarnishCost,
  foilCost,
  setFoilCost,
}: {
  hasFoil: boolean;
  devise: Devise;
  setDevise: React.Dispatch<Devise>;
  paperCost: number;
  setPaperCost: React.Dispatch<number>;
  inkCost: number;
  setInkCost: React.Dispatch<number>;
  primerCost: number;
  setPrimerCost: React.Dispatch<number>;
  varnishCost: number;
  setVarnishCost: React.Dispatch<number>;
  foilCost: number;
  setFoilCost: React.Dispatch<number>;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classNames(styles.onecontainer)}>
      <div className={classNames(styles.consommables)}>
        <div className={classNames(styles.titlezone)}>
          <p className={classNames(styles.title)}>
            {t("consumablescosts")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
        </div>
        <div className={classNames(styles.onecontent)}>
          <p>
            {t("devise")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <div className={classNames(styles.detailcontent)}>
                <label className={classNames(styles.select)} htmlFor="slct">
                  <select
                    id="slct"
                    onChange={(e) => setDevise(e.target.value as Devise)}
                    defaultValue={devise}
                  >
                    <option value="" disabled>
                      Select option
                    </option>
                    <option value="€">€</option>
                    <option value="$">$</option>
                    <option value="£">£</option>
                  </select>
                  <svg>
                    <use xlinkHref="#select-arrow-down"></use>
                  </svg>
                </label>

                <svg className={classNames(styles.sprites)}>
                  <symbol id="select-arrow-down" viewBox="0 0 10 6">
                    <polyline points="1 1 5 5 9 1"></polyline>
                  </symbol>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.onecontent)}>
          <p>
            {t("substratecost", {
              type: t("sheet"),
            })}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput)}
                type="text"
                name="paperC"
                id="paperC"
                defaultValue={paperCost}
                onChange={(e) => {
                  setPaperCost(parseFloat(e.target.value));
                }}
              />
              <span className={classNames(styles.unite)}>{devise}</span>
            </div>
          </div>
        </div>

        <div className={classNames(styles.onecontent)}>
          <p>
            {t("inkcost")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput)}
                type="text"
                name="inkC"
                id="inkC"
                defaultValue={inkCost}
                onChange={(e) => {
                  setInkCost(parseFloat(e.target.value));
                }}
              />
              <span className={classNames(styles.unite)}>{devise}</span>
            </div>
          </div>
        </div>

        <div className={classNames(styles.onecontent)}>
          <p>
            {t("primercost")}
            {i18n.language === "fr" ? " :" : ":"}
          </p>
          <div className={classNames(styles.sides)}>
            <div className={classNames(styles.sideA)}>
              <input
                className={classNames(styles.paddinginput)}
                type="text"
                name="paperC"
                id="paperC"
                defaultValue={primerCost}
                onChange={(e) => {
                  setPrimerCost(parseFloat(e.target.value));
                }}
              />
              <span className={classNames(styles.unite)}>{devise}</span>
            </div>
          </div>
        </div>

        {
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("varnishcost")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div className={classNames(styles.sideA)}>
                <input
                  className={classNames(styles.paddinginput)}
                  type="text"
                  name="varnishC"
                  id="varnishC"
                  defaultValue={varnishCost}
                  onChange={(e) => {
                    setVarnishCost(parseFloat(e.target.value));
                  }}
                />
                <span className={classNames(styles.unite)}>{devise}</span>
              </div>
            </div>
          </div>
        }

        {hasFoil && (
          <div className={classNames(styles.onecontent)}>
            <p>
              {t("foilcost")}
              {i18n.language === "fr" ? " :" : ":"}
            </p>
            <div className={classNames(styles.sides)}>
              <div className={classNames(styles.sideA)}>
                <input
                  className={classNames(styles.paddinginput)}
                  type="text"
                  name="foilC"
                  id="foilC"
                  defaultValue={foilCost}
                  onChange={(e) => {
                    setFoilCost(parseFloat(e.target.value));
                  }}
                />
                <span className={classNames(styles.unite)}>{devise}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AlphajetCosts;
