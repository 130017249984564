import React from "react";
import classNames from "classnames";
import styles from "./style/BigCostCalculator.module.scss";
import { CalculatorConfig, mapConfigToCase } from "../calculatorTypes";

const CompactModules = ({
  config,
  setConfig,
}: {
  config: CalculatorConfig;
  setConfig: React.Dispatch<CalculatorConfig>;
}) => {
  const configCase = mapConfigToCase(config);

  return (
    <>
      <div className={classNames(styles.onecontent)}>
        <p>Printer 1 (Clear Varnish)</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideB)}>
            <input
              className={classNames(
                styles.paddinginput2,
                configCase === "case4" && styles.disabledInput
              )}
              type="checkbox"
              name="printer1"
              disabled={configCase === "case4"}
              id="printer1"
              checked={config.printer1}
              onChange={(e) => {
                setConfig({ ...config, printer1: e.target.checked });
              }}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>Printer 2 (Anti Foil Varnish)</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideB)}>
            <input
              className={classNames(
                styles.paddinginput2,
                configCase === "case3" && styles.disabledInput
              )}
              type="checkbox"
              name="printer2"
              id="printer2"
              disabled={configCase === "case3"}
              checked={config.printer2}
              onChange={(e) => {
                setConfig({ ...config, printer2: e.target.checked });
              }}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.onecontent)}>
        <p>iFoil (Hot Foiling)</p>
        <div className={classNames(styles.sides)}>
          <div className={classNames(styles.sideB)}>
            <input
              className={classNames(
                styles.paddinginput2,
                configCase === "case6" && styles.disabledInput
              )}
              type="checkbox"
              name="ifoil"
              id="ifoil"
              checked={config.ifoil}
              disabled={configCase === "case6"}
              onChange={(e) => {
                setConfig({ ...config, ifoil: e.target.checked });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompactModules;
