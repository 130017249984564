import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Calculator } from "../calculatorTypes";

import archiveIcon from "../img/proj_archive.svg";
import deleteIcon from "../img/proj_delete.svg";

import modifyIcon from "../img/proj_modif.svg";
import styles from "./style/Hover.module.scss";
import { MachineModelType } from "@mgi-labs/lib-liste-machine";

const HoverActions = ({
  calculator,
  id,
  handleDeleteOne,
  handleDownload,
  machineType,
}: {
  id: number;
  calculator: Calculator;
  handleDeleteOne: (id: number) => Promise<void>;
  handleDownload: (calculator: Calculator) => Promise<void>;
  machineType: MachineModelType | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <span className={classNames(styles.hover_actions_container)}>
      <Link
        to={`/` + machineType + "/list/"}
        data-title={t<string>("archive")}
        onClick={(e) => {
          e.stopPropagation();
          handleDownload(calculator);
        }}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={archiveIcon}
          alt="Archiver le projet"
        />
      </Link>

      <Link
        to={"/" + machineType + "/list/"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleDeleteOne(id);
        }}
        data-title={t<string>("delete")}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={deleteIcon}
          alt="Supprimer le projet"
        />
      </Link>

      <Link
        to={"/" + machineType + `/new/` + id}
        onClick={(e) => e.stopPropagation()}
        data-title={t<string>("edit")}
      >
        <img
          className={classNames(styles.actionIcons)}
          src={modifyIcon}
          alt="Modifier le projet"
        />
      </Link>
    </span>
  );
};

export default HoverActions;
