export const defaultRoi = {
  calibrationInputs: {
    sqmPrice: 0.45,
    isSqm: true,
    foilInformation: {
      foilMasterRollCost: 110,
      foilRollLength: 400,
      foilMasterRollWidth: 640,
    },
    alphaJetGlobalPrintingCosts: {
      table: {
        transportCleaningRollerCost: {},
        epdmFoilRollerCost: {},
        dustStickyRollerCost: {},
        infraredLampCost: {},
        uvLampCost: {},
        headWipingCloth: {},
        inkjetHeadsCleaningFlush: {},
        table: {
          inkjetHeadsCleaningFlush: {
            name: "Inkjet heads cleaning flush",
            lifetime: 120,
            quantity: 1,
            cost: 51,
          },
          headWipingCloth: {
            name: "Head wiping cloth",
            lifetime: 75,
            quantity: 1,
            cost: 51,
          },
          uvLampCost: {
            name: "UV lamp",
            lifetime: 1500,
            quantity: 3,
            cost: 675,
          },
          infraredLampCost: {
            name: "Infrared lamp",
            lifetime: 12000,
            quantity: 2,
            cost: 655,
          },
          dustStickyRollerCost: {
            name: "Dust sticky roller",
            lifetime: 8765,
            quantity: 1,
            cost: 228,
          },
          epdmFoilRollerCost: {
            name: "EPDM Foil roller",
            lifetime: 2000,
            quantity: 1,
            cost: 3631,
          },
          transportCleaningRollerCost: {
            name: "Transport cleaning roller",
            lifetime: 8765,
            quantity: 1,
            cost: 213,
          },
        },
      },
      costPerUvVarnishLiter: 73,
      alphaJetGlobalPrintingCost: 90,
    },
    electricityCostPerKWHr: 0.08,
    rentCostPerMonthPerSqm2: 15,
    yearlyMaintenanceContract: {
      productionHoursPerDay: 8,
      workingDaysPerWeek: 5,
      workingDaysPerMonth: 21,
      monthPerYear: 12,
      mgiConnectSubscription: 0,
      maintenanceContractLength: 1,
      yearlyMaintenanceContract: 72000,
    },
    leaseDownpayment: 0,
    interestLeasingRate: 2,
    amortizationPeriod: 72,
    equipementCost: 3200000,
  },
  jobInfoInputs: {
    alphaJetInfos: {
      jobSalesPrice: 2200,
      alphaJetProductivity: 80,
      alphaJetPrintingSpeedPerHour: 1800,
      substratCostPerKg: 3,
      substratCostPer1000Sheet: 170,
      costPerOperatorPerHour: 30,
      alphaJetOperatorsNumber: 1,
    },
    back: {
      optimizedFoilUsagePerSheet: {},
      opv2d3dEffect: 0,
      opv: 0,
      digitalHotFoil2d3dEffect: 0,
      digitalHotFoilCoverage: 0,
      embellishmentUv2d3dEffect: 0,
      embellishmentSpotUvCoverage: 0,
      cmykPrintFileInkCoverage: 0,
    },
    front: {
      optimizedFoilUsagePerSheet: {},
      opv2d3dEffect: 3,
      opv: 10,
      digitalHotFoil2d3dEffect: 0,
      digitalHotFoilCoverage: 0,
      embellishmentUv2d3dEffect: 7,
      embellishmentSpotUvCoverage: 10,
      cmykPrintFileInkCoverage: 35,
    },
    weight: 100,
    numberOfSheets: 1000,
    upsPerSheet: 0,
    paperFormat: { width: 720, length: 1020 },
    digitalHotFoilEffect: false,
    doubleSided: false,
    isInKg: false,
    opv: true,
    internalEcoConso: true,
    paperCostForJob: 170,
  },
  inputEstimatedSheets: 0,
  overhead: 10,
  otherCostType: "fix",
  customerInputs: {
    spotUvHotFoilStamping: false,
    offsetPrintingType: "",
    digitalSolution: "",
    investmentProject: "None",
    customerMarket: ["Commercial Printing"],
    companyTurnover: "None",
    companyAddress: "",
    country: "France",
    mainProductionFormat: [],
    contactPhone: "",
    contactMail: "",
    contactName: "",
    companyName: "",
    yearlyEmbelishmentVolume: "",
  },
  roiInfo: { roiName: "defaultRoi", roiDesc: "default roi" },
};
