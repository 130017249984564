import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/choosemodal.module.scss";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";
import { useTranslation } from "react-i18next";
import {
  CalculatorConfig,
  CompactAssociation,
  mapConfigToCase,
} from "../calculatorTypes";

const ChoosemodalCompact = ({
  layers,
  setPdfId,
  setShowModal,
  machineType,
  setCompactVarnishAssociation,
  handleCancelUpload,
  config,
  setConfig,
}: {
  layers?: { document: string; preflight: string[] };
  setPdfId: React.Dispatch<string | undefined>;
  setShowModal: React.Dispatch<boolean>;
  machineType: string | undefined;
  setCompactVarnishAssociation: React.Dispatch<
    React.SetStateAction<CompactAssociation | undefined>
  >;
  handleCancelUpload: () => void;
  config: CalculatorConfig;
  setConfig: React.Dispatch<CalculatorConfig>;
}) => {
  const pdfToolBox = usePdfToolboxClient();

  const compactLayers = layers?.preflight.filter((e) => !isCMYK(e));

  const [selectedFoilLayer, setSelectedFoilLayer] = useState<string[]>([]);
  const [selectedVarnishLayer, setSelectedVarnishLayer] = useState<string>();
  const [selectedAntifoilLayer, setSelectedAntifoilLayer] = useState<string>();

  const { t } = useTranslation();

  const [localConfig, setLocalConfig] = useState<CalculatorConfig>(config);

  const configCase = mapConfigToCase(config);

  const inputSelectAssociation = useCallback(
    (c: string, l: React.ChangeEvent<HTMLSelectElement>) => {
      // CAS 1
      if (configCase === "case1") {
        l.target.value === "none"
          ? setSelectedVarnishLayer(undefined)
          : setSelectedVarnishLayer(l.target.value);
        setSelectedAntifoilLayer(undefined);
        setSelectedFoilLayer([]);
      }

      // CAS 2
      if (configCase === "case2") {
        if (c.includes("hotfoil")) {
          l.target.value === "none"
            ? setSelectedFoilLayer([])
            : setSelectedFoilLayer([l.target.value]);
        } else {
          l.target.value === "none"
            ? setSelectedVarnishLayer(undefined)
            : setSelectedVarnishLayer(l.target.value);
        }
        setSelectedAntifoilLayer(undefined);
      }

      // CAS 3
      if (configCase === "case3") {
        if (l.target.value === "none") {
          setSelectedVarnishLayer(undefined);
          setSelectedFoilLayer([]);
        } else {
          setSelectedVarnishLayer(l.target.value);
          setSelectedFoilLayer([l.target.value]);
        }
        setSelectedAntifoilLayer(undefined);
      }

      // CAS 4
      if (configCase === "case4") {
        if (c.includes("antifoil")) {
          l.target.value === "none"
            ? setSelectedAntifoilLayer(undefined)
            : setSelectedAntifoilLayer(l.target.value);
        } else if (l.target.value === "none") {
          setSelectedVarnishLayer(undefined);
          setSelectedFoilLayer([]);
        } else {
          setSelectedVarnishLayer(l.target.value);
          setSelectedFoilLayer([l.target.value]);
        }
      }

      // CAS 5
      if (configCase === "case5") {
        if (c.includes("antifoil")) {
          l.target.value === "none"
            ? setSelectedAntifoilLayer(undefined)
            : setSelectedAntifoilLayer(l.target.value);
        } else if (l.target.value === "none") {
          setSelectedVarnishLayer(undefined);
          setSelectedFoilLayer([]);
        } else {
          setSelectedVarnishLayer(l.target.value);
          setSelectedFoilLayer([]);
        }
      }

      // CAS 6
      if (configCase === "case6") {
        l.target.value === "none"
          ? setSelectedAntifoilLayer(undefined)
          : setSelectedAntifoilLayer(l.target.value);
        setSelectedVarnishLayer(undefined);
        setSelectedFoilLayer([]);
      }
    },
    [configCase]
  );

  const handleApplyLayers = useCallback(async () => {
    if (layers && (selectedAntifoilLayer || selectedVarnishLayer)) {
      try {
        await pdfToolBox
          .asyncProcess(
            layers?.document,
            [],
            selectedFoilLayer,
            selectedAntifoilLayer
          )
          .then((p) => {
            setPdfId(p.id);
            setCompactVarnishAssociation({
              varnish: selectedVarnishLayer,
              antifoil: selectedAntifoilLayer,
              foil: selectedFoilLayer[0],
            });
            setConfig(localConfig);
          });
      } finally {
        setShowModal(false);
      }
    }
  }, [
    layers,
    localConfig,
    pdfToolBox,
    selectedAntifoilLayer,
    selectedFoilLayer,
    selectedVarnishLayer,
    setCompactVarnishAssociation,
    setConfig,
    setPdfId,
    setShowModal,
  ]);

  return (
    <>
      {/* class displaynone pour cacher la modal */}
      <div className={classNames(styles.choosemodal)}>
        <div className={classNames(styles.closezone)}></div>
        <div className={classNames(styles.choosezone)}>
          <div className={classNames(styles.choosecase)}>
            <p className={classNames(styles.chooseModuleText)}>
              {t("select_modules")}
            </p>
            <div className={classNames(styles.checkboxContainer)}>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option1"
                  checked={localConfig.printer1}
                  disabled={configCase === "case4"}
                  className={classNames(
                    configCase === "case4" && styles.disabledInput
                  )}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      printer1: e.target.checked,
                    });
                    setConfig({ ...localConfig, printer1: e.target.checked });
                  }}
                />
                <label htmlFor="option1">
                  Printer 1 <br />
                  (Clear Varnish)
                </label>
              </div>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option2"
                  checked={localConfig.printer2}
                  disabled={configCase === "case3"}
                  className={classNames(
                    configCase === "case3" && styles.disabledInput
                  )}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      printer2: e.target.checked,
                    });
                    setConfig({ ...localConfig, printer2: e.target.checked });
                  }}
                />
                <label htmlFor="option2">
                  Printer 2 <br />
                  (Anti Foil Varnish)
                </label>
              </div>
              <div className={styles.inputDiv}>
                <input
                  type="checkbox"
                  name="option3"
                  checked={localConfig.ifoil}
                  disabled={configCase === "case6"}
                  className={classNames(
                    configCase === "case6" && styles.disabledInput
                  )}
                  onChange={(e) => {
                    setLocalConfig({
                      ...localConfig,
                      ifoil: e.target.checked,
                    });
                    setConfig({ ...localConfig, ifoil: e.target.checked });
                  }}
                />
                <label htmlFor="option3">
                  iFoil <br />
                  (Hot Foiling)
                </label>
              </div>
            </div>
          </div>

          <div className={classNames(styles.choosemodalcontent)}>
            {renderInputs(config).map((c) => (
              <>
                <div
                  className={classNames(styles.chooseinput)}
                  style={{ width: "100%", paddingBottom: "50px" }}
                >
                  <p className={classNames(styles.layerText)}>
                    {t(c + "text")}
                  </p>
                  <select
                    className={classNames(styles.select)}
                    onChange={(l) => {
                      inputSelectAssociation(c, l);
                    }}
                  >
                    <option value={"none"}>{t("none")}</option>
                    {compactLayers?.map((l) => (
                      <option value={l} key={`${l}-varnish`}>
                        {l}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ))}
          </div>

          <div className={classNames(styles.validate)}>
            <div
              className={classNames(styles.button)}
              onClick={handleCancelUpload}
            >
              <p>{t("cancel")}</p>
            </div>
            <div
              className={classNames(
                styles.button,
                !(selectedAntifoilLayer || selectedVarnishLayer) &&
                  styles.disabled
              )}
              onClick={() =>
                (selectedAntifoilLayer || selectedVarnishLayer) &&
                handleApplyLayers()
              }
            >
              <p>{t("apply")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChoosemodalCompact;

function isCMYK(layer: string) {
  return (
    layer.toUpperCase().includes("CYAN") ||
    layer.toUpperCase().includes("MAGENTA") ||
    layer.toUpperCase().includes("YELLOW") ||
    layer.toUpperCase().includes("BLACK") ||
    layer.toUpperCase().includes("JAUNE") ||
    layer.toUpperCase().includes("NOIR")
  );
}

function renderInputs(config: CalculatorConfig) {
  if (config.printer1) {
    if (config.printer2) {
      if (config.ifoil) {
        return ["clearVarnishHotFoil", "antifoil"];
      } else {
        return ["clearVarnish", "antifoil"];
      }
    } else if (config.ifoil) {
      return ["clearVarnish", "hotfoil"];
    } else return ["clearVarnish"];
  } else if (config.printer2) {
    return ["antifoil"];
  } else if (config.ifoil) {
    return ["clearVarnishHotFoil"];
  } else return [];
}
