import { PdfPreflightResponse } from "../pdfPreflight.interface";

export type UploadProgressHandler = (
  projectId: number,
  event: ProgressEvent<EventTarget>
) => void;

export default interface PdfToolboxClient {
  /**
   * Reads pdf file and uploads it to the pdftoolbox server.
   * Does NOT expect packaging design in the server response.
   */
  asyncUpload: (
    pdfFile: File
  ) => Promise<{ document: string; preflight: string[] }>;
  asyncFetch: (
    id: string
  ) => Promise<{ step: number; preflight?: PdfPreflightResponse[] }>;
  asyncProcess: (
    document: string,
    preflight: string[],
    foils: string[],
    antifoil: string | undefined
  ) => Promise<{ id: string }>;
}

export class HttpPdfToolBoxClient implements PdfToolboxClient {
  private readonly pdfUploadingURL: URL;
  private readonly pdfSatusURL: URL;
  private readonly pdfProcessingURL: URL;

  constructor(private readonly pdfToolboxRootUrl: string) {
    this.pdfUploadingURL = new URL("/upload", this.pdfToolboxRootUrl);
    this.pdfSatusURL = new URL("/status", this.pdfToolboxRootUrl);
    this.pdfProcessingURL = new URL("/processing", this.pdfToolboxRootUrl);
  }

  async asyncUpload(
    file: File
  ): Promise<{ document: string; preflight: string[] }> {
    return await this.uploadWithXhr(file);
  }

  async asyncProcess(
    document: string,
    preflight: string[],
    foils: string[],
    antifoil: string | undefined
  ) {
    return await this.processWithXhr(document, preflight, foils, antifoil);
  }

  async asyncFetch(
    id: string
  ): Promise<{ step: number; preflight: PdfPreflightResponse[] }> {
    return await this.getPdfProgress(id);
  }

  private uploadWithXhr(
    file: File
  ): Promise<{ document: string; preflight: string[] }> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.onerror = () => reject(xhr);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.response));
        } else {
          reject(xhr);
        }
      };

      xhr.open("POST", this.pdfUploadingURL.toString());
      xhr.send(this.initFormData(file));
    });
  }
  private processWithXhr(
    document: string,
    preflight: string[],
    foils: string[],
    antifoil: string | undefined
  ): Promise<{ id: string }> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ document, preflight, foils, antifoil }),
    };
    const response: Promise<{ id: string }> = fetch(
      this.pdfProcessingURL.toString(),
      requestOptions
    ).then((response) => response.json());
    return response;
  }

  private getPdfProgress(
    id: string
  ): Promise<{ step: number; preflight: PdfPreflightResponse[] }> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.onerror = () => reject(xhr);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.response));
        } else {
          reject(xhr);
        }
      };

      xhr.open("GET", this.pdfSatusURL.toString() + "/" + id);
      xhr.send();
    });
  }

  private initFormData(file: File): FormData {
    const formData = new FormData();
    formData.append("pdf", file);
    return formData;
  }
  private initFormData2(document: string, preflight: string[]): FormData {
    const formData = new FormData();
    formData.append("document", document);
    formData.append("preflight", JSON.stringify(preflight));
    return formData;
  }
}
